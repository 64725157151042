/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import GPSDesign from '../assets/Images/Icons/GreypathDesignLogo.svg'
import BackBox from '../assets/Images/Icons/BackBox.svg'
import KmbTop from '../assets/Images/top-banner-images/KMBTOP.jpg'
import KmbLogo from '../assets/Images/bg/kmb-logo.svg'
import ExternalArrow from '../assets/Images/Icons/external-arrow.png'
import bottom from '../assets/Images/Projects/kmb/KMBBottom.jpg'
import KmbSlider from '../Components/KmbSlider'
import Footer from '../Components/FooterPro'
import { Link, useNavigate } from 'react-router-dom';

function useKmb(probs) {
    const navigate = useNavigate();
    const scrollToServices = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("service")
    };
    const scrollToBrand = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("brand")
    };
    const scrollToPrint = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("print")
    };
    const scrollToConsult = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("consult")
    };
    const scrollToApp = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("app")
    };
    const scrollToPro = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("pro")
    };
    const toHome = (val) => {
        navigate(`/?section=${val}`);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);
    return (
        <div className="project-page">
            <div className="bg-grey">
                <div className="container-fl navs bg-grey">
                    <div className="header-main d-flex justify-content-between">
                        <Link to="/" >
                            <img src={GPSDesign} class="primary-logo pointer dis-none" alt="image" />
                        </Link>


                        <div class="text-right">
                            <a class="nav-items dis-none" onClick={() => toHome("service")}>ABOUT</a>
                            <a class="nav-items dis-none" onClick={() => toHome("partner")}>PARTNERS</a>
                            <a class="nav-items dis-none" onClick={() => toHome("contact")}>CONTACT</a>
                        </div>
                        <button id="hiddenButton" style={{ display: "none" }}></button>

                    </div>
                    <div class="">
                        <div class="col col-xl-12">
                            <Link to="/" >
                                <img src={BackBox} alt="" width="32" height="32" class="mb-4 mb-lg-0 cursor-pointer" id="backButton" />
                            </Link>
                            <h1 className="project-title">KMB Group</h1>
                        </div>
                    </div>
                    <div className="col col-md-10 col-lg-8 col-xl-8 mt-nec-25 mb-lg-2">
                        <div className="dispaly-flex">
                            <button className="primary-btn bg-grey label-txt mr-3 grey-text mb-2 ">Brand</button>
                            <button className="primary-btn bg-grey label-txt mr-3 grey-text mb-2 ">Website Design & Dev</button>
                            <button className="primary-btn bg-grey label-txt mr-3 grey-text mb-2 ">Content</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container-fl">
                    <div className="project-banner-section container-full">
                        <div className="pro-img-div">
                            <img src={KmbTop} alt="" className="pro-img" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fl">
                <div className="container-fluid custom-fonts mt-70">
                    <div className="row">
                        <div className="col-sm-4 d-flex align-items-center justify-content-center">
                            <div
                                className="bg-grey text-center d-flex align-items-center justify-content-center logo-background">
                                <img src={KmbLogo} alt="" className="projects_logo_img mb-4 mb-lg-0" loading="lazy"/>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <p className="para-sm grey-text mx-5 mx-lg-0 mx-md-0 mb-lg-5">KMB Group, a luxury real estate developer, focuses on boutique residential and mixed-use projects. With a legacy dating back to the 1950s in Mumbai, they now aim to set new benchmarks for luxury urban living through various real-estate projects and products.
                            </p>
                            <div className="d-flex  project-bullet-list">
                                <div className="mr-15">
                                    <p className="para-mid grey-text">Brand Guidelines </p>
                                    <p className="para-mid grey-text">Design Wireframes</p>
                                    <p className="para-mid grey-text">Website UI Design</p>
                                    <p className="para-mid grey-text">Website Copy</p>
                                </div>
                                <div>
                                    <p className="para-mid grey-text">Website Development</p>
                                    <p className="para-mid grey-text">Support & Maintenance</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <KmbSlider />

            <div className="">
                <div className="container-fl">
                    <div className="project-banner-section mt-70 container-full">
                        <div className="pro-img-div bots">
                            <img src={bottom} alt="" className="pro-img" loading="lazy"/>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="project-big-button">
                                <a href="https://www.kmbgroup.in/" target="_blank">
                                    <p className="text-center white-text para-md dis-none visit_website_font">VISIT THE WEBSITE</p>
                                    <div
                                        className="d-flex d-sm-flex d-md-block d-lg-flex d-xl-flex text-center align-items-center justify-content-center">
                                        <h3 className="text-center white-text para-xl text-decor-unline">
                                            www.kmbgroup.com</h3>
                                        <img src={ExternalArrow} alt=""
                                            className="ml-3 external-aroww-img mb-3" loading="lazy"/>
                                    </div>
                                </a>
                            </button>

                        </div>
                    </div>
                </div>

            </div>
            <Footer scrollToServices={scrollToServices} scrollToBrand={scrollToBrand} scrollToPrint={scrollToPrint}
                scrollToConsult={scrollToConsult} scrollToApp={scrollToApp} scrollToPro={scrollToPro} />
        </div>
    )
}
export default useKmb