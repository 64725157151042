/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SAF from '../assets/Images/logo/SAF.png'
import SAFLogo from '../assets/Images/logo/SAFLOGO.svg'

import UIC from '../assets/Images/logo/UIC.png'
import UICLogo from '../assets/Images/logo/UICLOGO.svg'

import LE from '../assets/Images/logo/LE.png'
import LELogo from '../assets/Images/logo/LELOGO.svg'

import LL from '../assets/Images/Shapes/KMB.svg'
import LLLogo from '../assets/Images/Icons/KMBLOGO.svg'

import LP from '../assets/Images/logo/LP.png'
import LPLogo from '../assets/Images/logo/LPLOGO.svg'

import BR from '../assets/Images/logo/BR.png'
import BRLogo from '../assets/Images/logo/BRLOGO.svg'

import Assetz from '../assets/Images/logo/Assetz.png'
import AssetzLogo from '../assets/Images/logo/AssetzLogo.svg'

import AKS from '../assets/Images/logo/ASB.png'
import AKSLogo from '../assets/Images/logo/ASBLOGO.svg'

import Pro63 from '../assets/Images/logo/63EAST.png'
import Pro63Logo from '../assets/Images/logo/63LOGO.svg'

import HH from '../assets/Images/logo/HN.png'
import HHLogo from '../assets/Images/logo/HNLOGO.svg'

import OCK from '../assets/Images/logo/18OCK.png'
import OCKLogo from '../assets/Images/logo/18&OCK.svg'

import ISA from '../assets/Images/logo/ISA.png'
import ISALogo from '../assets/Images/logo/ISALOGO.png'

import PrintP from '../assets/Images/logo/PDC.jpg'
import PdcLogo from "../assets/Images/Icons/PDCLOGO.svg"

import { Link } from 'react-router-dom';

function MySlickCarousel(probs) {
    const sliderRef = useRef(null);
    const loadMoreRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [all, setAll] = useState(true);
    const [design, setDesign] = useState(false);
    const [brand, setBrand] = useState(false);
    const [dev, setDev] = useState(false);
    const [present, setPresent] = useState(false);
    const [ui, setUi] = useState(false);
    const [print, setPrint] = useState(false);
    const [content, setContent] = useState(false);
    const [app, setApp] = useState(false);
    const [integartion, setIntegartion] = useState(false);
    const [collateral, setCollateral] = useState(false);
    const [strategy, setStrategy] = useState(false);
    const [consult, setConsult] = useState(false);

    const [loadMo, setLoadMo] = useState(() => {
        const stored = sessionStorage.getItem("loadMore");
        return stored === null ? false : stored === 'true';
    })
    useEffect(() => {


        console.log('typeof', typeof sessionStorage.getItem('loadMore'));

        const trackScrollPosition = () => {
            sessionStorage.setItem('scrollPositionX', window.scrollX);
            sessionStorage.setItem('scrollPositionY', window.scrollY);


        };




        window.addEventListener('scroll', trackScrollPosition);


        return () => {
            window.removeEventListener('scroll', trackScrollPosition);
        };



    }, []);




    useEffect(() => {

        sessionStorage.setItem("loadMore", loadMo);
    }, [loadMo])





    const handleLoadMore = () => {

        setLoadMo(load => !load);


        if (loadMoreRef.current) {


            loadMoreRef.current.scrollIntoView({ behavior: 'smooth' });
        }






    }

    const slidesData = [
        {
            id: "SAF",
            key: 1,
            category: ["Branding", "", "Presentation", "Strategy", "Content", "Website Development", "Website Design", "UX/UI", "Consult"],
            content:
                <Link to="/SAF" className="sliderss cursor-pointer" data-category="WebsiteDesign-UX/UI-WebsiteDevelopment-ApplicationDesign" id="UIC" >
                    <div className="slider-inner-section">
                        <div className="hi-index">
                            <h4 className="mb-0 silder-title-text">Sofia Akash Foundation</h4>
                            <p className="para-mid grey-text">Branding &#183; Website Design & Dev &#183; Printing Services</p>
                        </div>
                        <div>
                            <div className="slick-img-div">
                                <img className="slick-img" src={SAF} />
                            </div>
                            <div className="slick-second-section">
                                <img className="kmb-logo saf" src={SAFLogo} />
                            </div>
                        </div>
                    </div>
                </Link>
        },
        {
            id: "UIC",
            key: 2,
            category: ["Website Development", "Website Design", "UX/UI", "Application Design", "Consult", "Integrations"],
            content: <Link to="/UIC" className="sliderss cursor-pointer" data-category="WebsiteDesign-UX/UI-WebsiteDevelopment-ApplicationDesign" id="UIC" >
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">Union Insurance</h4>
                        <p className="para-mid grey-text">Content &#183;  UX/UI &#183; Website Design & Dev </p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <img className="slick-img " src={UIC} />
                        </div>
                        <div className="slick-second-section">
                            <img className="kmb-logo ui" src={UICLogo} />
                        </div>
                    </div>
                </div>
            </Link>
        },
        {
            id: "Lendeast",
            key: 3,
            category: ["UX/UI", "Integrations", "Website Development", "Website Design", "Strategy", "Application Design"],
            content: <Link to="/Lendeast" className="sliderss cursor-pointer" data-category="UX/UI-Integrations-WebsiteDevelopment" id="lend_east" >
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">Lend East</h4>
                        <p className="para-mid grey-text">UX/UI &#183; Integrations &#183; Website Design & Dev</p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <img class="slick-img " src={LE} />
                        </div>
                        <div className="slick-second-section">
                            <img className="kmb-logo ui" src={LELogo} />
                        </div>
                    </div>
                </div>
            </Link>
        },
        {
            id: "KMB",
            key: 4,
            category: ["UX/UI", "Website Development", "Website Design", "Content", "Branding"],
            content: <Link to="/kmb" className="sliderss cursor-pointer" data-category="WebsiteDesign-WebsiteDevelopment-Content" id="KMB" >
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">KMB Group</h4>
                        <p className="para-mid grey-text">Content &#183; Integrations &#183; Website Design & Dev</p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <div className="slick-img kmb-bg d-flex justify-content-center">
                                <img className="kmb-img" src={LL} />
                            </div>

                        </div>
                        <div className="slick-second-section">
                            <img className="kmb-logo kmb" src={LLLogo} />
                        </div>
                    </div>
                </div>
            </Link>
        },
        {
            id: "Palazzo",
            key: 5,
            category: ["Website Development", "Website Design", "Content", "Branding"],
            content: <Link to="/Lapalazzo" className="sliderss cursor-pointer" data-category="WebsiteDesign-Content-Integrations" id="Palazzo" >
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">La Palazzo</h4>
                        <p className="para-mid grey-text">Content &#183; Integrations &#183; Website Design & Dev</p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <div className="slick-img kmb-bg d-flex justify-content-center">
                                <img className="slick-img" src={LP} />
                            </div>

                        </div>
                        <div className="slick-second-section">
                            <img className="kmb-logo saf" src={LPLogo} />
                        </div>
                    </div>
                </div>
            </Link>
        },
        {
            id: "Brigade",
            key: 6,
            category: ["UX/UI", "Website Development", "Website Design", "Content", "Integrations"],
            content: <Link to="/brigadeResidences" className="sliderss cursor-pointer" data-category="WebsiteDesign-WebsiteDevelopment" id="Brigade" >
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">Brigade Residences</h4>
                        <p className="para-mid grey-text">Integrations &#183; Website Design & Dev</p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <div className="slick-img kmb-bg d-flex justify-content-center">
                                <img className="slick-img" src={BR} />
                            </div>

                        </div>
                        <div className="slick-second-section">
                            <img className="kmb-logo" src={BRLogo} />
                        </div>
                    </div>
                </div>
            </Link>
        },
        {
            id: "Akanksha",
            key: 7,
            category: ["Website Development", "Website Design", "Content", "Branding", "UX/UI", "Application Design", "Integrations"],
            content: <Link to="/asb" className="sliderss cursor-pointer" data-category="WebsiteDesign-Content-Branding-ApplicationDesign" id="Akanksha">
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">Akanksha Singh Basketball</h4>
                        <p className="para-mid grey-text">Content &#183; Integrations &#183; Website Design & Dev</p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <div className="slick-img kmb-bg d-flex justify-content-center">
                                <img className="slick-img" src={AKS} />
                            </div>

                        </div>
                        <div className="slick-second-section">
                            <img className="kmb-logo" src={AKSLogo} />
                        </div>
                    </div>
                </div>
            </Link>
        },
        {
            id: "print",
            key: 8,
            category: ["Printing Services", ""],
            content: <Link to="/Printing" className="sliderss cursor-pointer" data-category="WebsiteDevelopment-UX/UI-WebsiteDevelopment" id="ISA" >
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">Custom Printing </h4>
                        <p className="para-mid grey-text">Stationery &#183; Journals &#183; Diaries &#183;  Gifting
                        </p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <div className="slick-img kmb-bg d-flex justify-content-center">
                                <img className="slick-img" src={PrintP} />
                            </div>

                        </div>
                        <div className="slick-second-section d-flex justify-content-center">
                            <img className="kmb-logo ui" src={PdcLogo} style={{ width: " 40%" }} />
                        </div>
                    </div>
                </div>
            </Link>
        },
        {
            id: "Assetz",
            key: 9,
            category: ["UX/UI", "Website Development", "Website Design", "Presentation", "Content", "Application Design", "Collateral"],
            content: <Link to="/assetz" className="sliderss cursor-pointer" data-category="WebsiteDesign-Presentation-WebsiteDevelopment" id="Assetz" >
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">Assetz</h4>
                        <p className="para-mid grey-text">Presentations &#183; Brochures &#183;  Website Design & Dev
                        </p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <div className="slick-img kmb-bg d-flex justify-content-center">
                                <img className="slick-img" src={Assetz} />
                            </div>

                        </div>
                        <div className="slick-second-section">
                            <img className="kmb-logo ui" src={AssetzLogo} />
                        </div>
                    </div>
                </div>
            </Link>
        },
        {
            id: "Assetz63",
            key: 10,
            category: ["Website Development", "Website Design", "Collateral", "Branding", "Content"],
            content: <Link to="/assetz_63" className="sliderss cursor-pointer" data-category="WebsiteDesign-PrintingServices-Presentation"
                id="Assetz63">
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">63 Degree East</h4>
                        <p className="para-mid grey-text">Website Design & Dev &#183; Brochures &#183; Content</p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <div className="slick-img kmb-bg d-flex justify-content-center">
                                <img className="slick-img" src={Pro63} />
                            </div>

                        </div>
                        <div className="slick-second-section">
                            <img className="kmb-logo ui" src={Pro63Logo} />
                        </div>
                    </div>
                </div>
            </Link>
        },
        {
            id: "HNH",
            key: 11,
            category: ["Website Development", "Website Design", "", "Collateral"],
            content: <Link to="/hereNow" className="sliderss cursor-pointer" data-category="WebsiteDesign-PrintingServices" id="HNH" >
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">Here & Now</h4>
                        <p className="para-mid grey-text">Website Design & Dev &#183; Brochures</p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <div className="slick-img kmb-bg d-flex justify-content-center">
                                <img className="slick-img" src={HH} />
                            </div>

                        </div>
                        <div className="slick-second-section">
                            <img className="kmb-logo ui" src={HHLogo} />
                        </div>
                    </div>
                </div>
            </Link>
        },
        {
            id: "18nok",
            key: 12,
            category: ["Collateral", "Presentation"],
            content: <Link to="/oak" className="sliderss cursor-pointer" data-category="WebsiteDesign-PrintingServices" id="18nok" >
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">18 & Oak</h4>
                        <p className="para-mid grey-text">Website Design & Dev &#183; Brochures &#183; Content</p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <div className="slick-img kmb-bg d-flex justify-content-center">
                                <img className="slick-img" src={OCK} />
                            </div>

                        </div>
                        <div className="slick-second-section">
                            <img className="kmb-logo saf" src={OCKLogo} />
                        </div>
                    </div>
                </div>
            </Link>
        },
        {
            id: "ISA",
            key: 13,
            category: [ "UX/UI", "Integrations", "Application Design"],
            content: <Link to="/ISA" className="sliderss cursor-pointer" data-category="WebsiteDevelopment-UX/UI-WebsiteDevelopment" id="ISA" >
                <div className="slider-inner-section">
                    <div className="hi-index">
                        <h4 className="mb-0 silder-title-text">ISA</h4>
                        <p className="para-mid grey-text">UX/UI &#183; Integrations &#183; Website Design & Dev
                        </p>
                    </div>
                    <div>
                        <div className="slick-img-div">
                            <div className="slick-img kmb-bg d-flex justify-content-center">
                                <img className="slick-img" src={ISA} />
                            </div>

                        </div>
                        <div className="slick-second-section d-flex justify-content-center">
                            <img className="kmb-logo ui" src={ISALogo} style={{ width: " 40%" }} />
                        </div>
                    </div>
                </div>
            </Link>
        },

        {
            id: "empty",
            key: 14,
            category: ['Website Design', 'Website Development', 'Branding', 'Presentation', 'UX/UI', 'Printing Services', 'Content', 'Application Design', 'Integrations', 'Strategy', 'Consult', 'Collateral'],
            content: <></>
        },
        {
            id: "empty",
            key: 15,
            category: ['Website Design', 'Website Development', 'Branding', 'Presentation', 'UX/UI', 'Printing Services', 'Content', 'Application Design', 'Integrations', 'Strategy', 'Consult', 'Collateral'],
            content: <></>
        },
        // Add more slides as needed
    ];
    let activeCategories = [];
    if (all) {
        activeCategories = ['Website Design', 'Website Development', 'Branding', 'Presentation', 'UX/UI', 'Printing Services', 'Content', 'Application Design', 'Integrations', 'Strategy', 'Consult', 'Collateral'] // Include all categories
    } else {
        if (design) activeCategories.push("Website Design");
        if (brand) activeCategories.push("Branding");
        if (dev) activeCategories.push("Website Development");
        if (present) activeCategories.push("Presentation");

        if (ui) activeCategories.push("UX/UI");
        if (print) activeCategories.push("Printing Services");
        if (content) activeCategories.push("Content");
        if (app) activeCategories.push("Application Design");
        if (integartion) activeCategories.push("Integrations");
        if (strategy) activeCategories.push("Strategy");
        if (consult) activeCategories.push("Consult");
        if (collateral) activeCategories.push("Collateral");
    }

    // Filter slides to display based on active categories
    let filteredSlides = slidesData.filter(slide =>
        all || slide.category.some(cat => activeCategories.includes(cat))
    );
    filteredSlides.sort((a, b) => a.key - b.key);
    console.log(filteredSlides, 'filteredSlides')


    const settings = {
        initialSlide: 0,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        afterChange: current => setCurrentSlide(current),
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 2 } },
            { breakpoint: 600, settings: { slidesToShow: 1 } }
        ]
    };
    const handleAllClick = () => {
        setAll(true);


        setDesign(false);
        setBrand(false);
        setDev(false);
        setPresent(false);
        setStrategy(false);
        setCollateral(false);
        setPrint(false)
        setConsult(false)
        setIntegartion(false)
        setApp(false)
        setUi(false)
        setContent(false)

    };

    const handleCategoryClick = (setter, sat, set1, set2, set3, set4, set5, set6, set7, set8, set9, set10, set11) => {
        if (sat === false) {
            setter(prev => !prev);
            setAll(false);
        }
        else if (sat === true) {
            setter(prev => !prev);
            if (!set1 && !set2 && !set3 && !set4 && !set5 && !set6 && !set7 && !set8 && !set9 && !set10 && !set11) {
                setAll(true);
            }
            else {
                setAll(false);
            }

        }




    };

    useEffect(() => {
        // toggleLoadMoreContent()
        const handleWheel = (event) => {
            if (!sliderRef.current) return;

            const delta = event.deltaY;
            let nextSlide = currentSlide + (delta > 0 ? 1 : -1);

            // Check if the slider is at the beginning or the end
            const atBeginning = currentSlide === 0 && delta < 0;
            const atEnd = currentSlide === sliderRef.current.props.children.length - settings.slidesToShow && delta > 0;

            if (atBeginning || atEnd) {
                // Allow default scroll behavior
                return;
            }

            event.preventDefault(); // Prevent scrolling the page

            nextSlide = Math.max(0, Math.min(nextSlide, sliderRef.current.props.children.length - settings.slidesToShow));
            sliderRef.current.slickGoTo(nextSlide);
        };


        // Add event listener
        const sliderElement = document.querySelector('.slick-slider'); // Adjust the selector if necessary
        sliderElement.addEventListener('wheel', handleWheel, { passive: false });
        if (!design && !brand && !dev && !present && !ui && !print && !content && !app && !integartion && !collateral && !strategy && !consult) {
            setAll(true);
        }

        // Cleanup
        return () => {
            if (sliderElement) {
                sliderElement.removeEventListener('wheel', handleWheel);
            }
        };
    }, [currentSlide, settings.slidesToShow, design, brand, dev, present, ui, print, content, app, integartion, collateral, strategy, consult, loadMo]);

    return (
        <>
            <div className="bg-grey">
                <div className="container-fl navs bg-grey pt-0 liftTop">
                    <div className="custom-fonts">
                        <div className="col col-xl-8">
                            <h1 className="gradient-text">Design & Consulting Expertise For Your Business Needs.</h1>
                        </div>
                    </div>

                    <div className="col col-md-11 col-lg-8 col-xl-6 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                        <div className="dispaly-flex">
                            <button id="allButton" className={`arrow-button all-button ${all ? "" : "disabled"}`} onClick={handleAllClick}>All</button>
                            <button className={`arrow-button ${ui ? "" : "disabled"}`} data-target="sliderss" data-category="WebsiteDesign" onClick={() => handleCategoryClick(setUi, ui, design, dev, brand, present, collateral, print, content, app, integartion, consult, strategy)}>UX/UI</button>
                            <button className={`arrow-button ${design ? "" : "disabled"}`} data-target="sliderss" data-category="WebsiteDevelopment" onClick={() => handleCategoryClick(setDesign, design, ui, dev, brand, present, collateral, print, content, app, integartion, consult, strategy)}>Website Design & Dev</button>
                            {/* <button className={`arrow-button ${dev ? "" : "disabled"}`} data-target="sliderss" data-category="Branding" onClick={() => handleCategoryClick(setDev, dev, ui, design, brand, present, collateral, print, content, app, integartion, consult, strategy)}>Website Development</button> */}
                            <button className={`arrow-button ${brand ? "" : "disabled"}`} data-category="Presentation" onClick={() => handleCategoryClick(setBrand, brand, ui, design, dev, present, collateral, print, content, app, integartion, consult, strategy)}>Branding</button>
                            <button className={`arrow-button ${present ? "" : "disabled"}`} data-target="sliderss" data-category="UX/UI" onClick={() => handleCategoryClick(setPresent, present, ui, design, dev, brand, collateral, print, content, app, integartion, consult, strategy)}> Presentations</button>
                            <button className={`arrow-button ${collateral ? "" : "disabled"}`} data-target="sliderss" data-category="PrintingServices" onClick={() => handleCategoryClick(setCollateral, collateral, ui, design, dev, brand, present, print, content, app, integartion, consult, strategy)}>Print Collateral</button>
                            <button className={`arrow-button ${print ? "" : "disabled"}`} data-target="sliderss" data-category="Content" onClick={() => handleCategoryClick(setPrint, print, ui, design, dev, brand, present, collateral, content, app, integartion, consult, strategy)}>Printing Services</button>
                            <button className={`arrow-button ${content ? "" : "disabled"}`} data-target="sliderss" data-category="ApplicationDesign" onClick={() => handleCategoryClick(setContent, content, ui, design, dev, brand, present, collateral, print, app, integartion, consult, strategy)}>Content </button>
                            {/* <button className={`arrow-button ${app ? "" : "disabled"}`} data-target="sliderss" data-category="Integrations" onClick={() => handleCategoryClick(setApp, app, ui, design, dev, brand, present, collateral, print, content, integartion, consult, strategy)}>Applications</button> */}
                            <button className={`arrow-button ${integartion ? "" : "disabled"}`} data-target="sliderss" data-category="Integrations" onClick={() => handleCategoryClick(setIntegartion, integartion, ui, design, dev, brand, present, collateral, print, content, app, consult, strategy)}>Integrations</button>
                            {/* <button className={`arrow-button ${consult ? "" : "disabled"}`} data-target="sliderss" data-category="Integrations" onClick={() => handleCategoryClick(setConsult, consult, ui, design, dev, brand, present, collateral, print, content, app, integartion, strategy)}>Consulting Services</button> */}
                            {/* <button className={`arrow-button ${strategy ? "" : "disabled"}`} data-target="sliderss" data-category="Integrations" onClick={() => handleCategoryClick(setStrategy, strategy, ui, design, dev, brand, present, collateral, print, content, app, integartion, consult)}>Strategy</button> */}
                        </div>
                    </div>
                    <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none mt-4 custom-fonts">
                        <p className="para-label mb-0 black_tex lh-18 opt-50">Website Design & Dev &#8226; Branding</p>
                        <p className="para-label mb-0 black_tex lh-18 opt-50">Presentations &#8226; UX/UI
                            &#8226; Print Collateral</p>
                        <p className="para-label mb-0 black_tex lh-18 opt-50">Printing Services &#8226; Content &#8226; Application Design
                        </p>
                        <p className="para-label mb-0 black_tex lh-18 opt-50">Integrations &#8226; Consulting Services &#8226; Strategy
                        </p>
                    </div>
                </div>

            </div>
            <div class="bg-grey">
                <div class='container-fl slicks bg-grey custom-fonts pb-0'>
                    <div class="d-sm-block d-none">
                        <div class="slick-top-sec d-flex">
                            <p class="para-mid text-t-grey mb-2 mr-4">{all ? "SHOWING ALL PROJECTS" : "SHOWING ONLY SELECTED PROJECTS"}</p>

                        </div>

                    </div>
                    <div class="d-block d-sm-none">
                        <div class="slick-top-sec d-flex">
                            <p class="para-mid text-t-grey mb-2 mr-4">PROJECTS</p>
                        </div>
                    </div>
                </div>
                <div class="top-bot">
                </div>
            </div>
            <div className='container-fl slicks bg-grey custom-fonts pt-0 d-none d-sm-none d-md-block d-lg-block d-xl-block Silder_text' id="proplus" ref={probs.proRef}>
                <Slider ref={sliderRef} {...settings} class="slick-carousel ">
                    {filteredSlides.map((slide, i) => (<div key={i}> {slide.content}</div>))}
                </Slider>

            </div>
            <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none container-fl slicks bg-grey custom-fonts pt-0 pb-0 Silder_text" ref={probs.proMobRef}
                id="loads" style={{ position: "relative", zIndex: "10" }}>
                <div class="d-block d-sm-flex" id="proplus-mob">
                    <div class="col-12 col-sm-6">
                        <Link to="/SAF" class="slider-inner-section" >
                            <div class="hi-index">
                                <h4 class="mb-0 silder-title-text">Sofia Akash Foundation</h4>
                                <p class="para-mid grey-text">Branding &#183; Website Design & Dev</p>
                            </div>
                            <div>
                                <div class="slick-img-div">
                                    <div class="slick-img kmb-bg d-flex justify-content-center">
                                        <img class="" src={SAF} />
                                    </div>

                                </div>
                                <div class="slick-second-section">
                                    <img class="kmb-logo saf" src={SAFLogo} />
                                </div>
                            </div>
                        </Link>

                    </div>
                    <div class="col-12 col-sm-6">
                        <Link to="/UIC" class="slider-inner-section">
                            <div class="hi-index">
                                <h4 class="mb-0 silder-title-text">Union Insurance</h4>
                                <p class="para-mid grey-text">UX/UI &#183; Content &#183; Website Design & Dev</p>
                            </div>
                            <div>
                                <div class="slick-img-div">
                                    <img class="slick-img" src={UIC} />
                                </div>
                                <div class="slick-second-section" ref={loadMoreRef}>
                                    <img class="kmb-logo ui" src={UICLogo} />
                                </div>
                            </div>
                        </Link>
                    </div>
                    {!loadMo ? <>
                        <div id="load-none" class="col-12 col-sm-6">
                            <Link to="/Lendeast" class="slider-inner-section opt-10 pb-0">
                                <div class="hi-index">
                                    <h4 class="mb-0 silder-title-text">Lend East</h4>
                                    <p class="para-mid grey-text">UX/UI &#183; Integrations &#183; Website Design & Dev</p>
                                </div>
                                <div>
                                    <div class="slick-img-div">
                                        <img class="" src={LE}
                                            style={{ height: "200px", width: "100%", objectFit: "cover" }} />
                                    </div>

                                </div>
                            </Link>
                        </div>

                    </> : ""}
                    {!loadMo ? "" : <>
                        <div class="col-12 col-sm-6">
                            <Link to="/Lendeast" class="slider-inner-section">
                                <div class="hi-index">
                                    <h4 class="mb-0 silder-title-text">Lend East</h4>
                                    <p class="para-mid grey-text">UX/UI &#183; Integrations &#183; Website Design & Dev</p>
                                </div>
                                <div class="slick-img-div">
                                    <img class="slick-img " src={LE} />
                                </div>
                                <div class="slick-second-section">
                                    <img class="kmb-logo ui" src={LELogo} />
                                </div>
                            </Link>
                            <div>
                                <Link to="/kmb" class="slider-inner-section">
                                    <div class="hi-index">
                                        <h4 class="mb-0 silder-title-text">KMB Group</h4>
                                        <p class="para-mid grey-text">Content &#183; Integrations &#183; Website Design & Dev </p>
                                    </div>
                                    <div>
                                        <div class="slick-img-div">
                                            <div class="slick-img kmb-bg d-flex justify-content-center">
                                                <img class="kmb-img" src={LL} />
                                            </div>

                                        </div>
                                        <div class="slick-second-section">
                                            <img class="kmb-logo kmb" src={LLLogo} />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to="/Lapalazzo" class="slider-inner-section">
                                    <div class="hi-index">
                                        <h4 class="mb-0 silder-title-text">La Palazzo</h4>
                                        <p class="para-mid grey-text">Content &#183; Integrations &#183; Website Design & Dev</p>
                                    </div>
                                    <div>
                                        <div class="slick-img-div">
                                            <div class="slick-img kmb-bg d-flex justify-content-center">
                                                <img class="slick-img" src={LP} />
                                            </div>

                                        </div>
                                        <div class="slick-second-section">
                                            <img class="kmb-logo saf" src={LPLogo} />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div>

                                <Link to="/brigadeResidences" class="slider-inner-section">
                                    <div class="hi-index">
                                        <h4 class="mb-0 silder-title-text">Brigade Residences</h4>
                                        <p class="para-mid grey-text">Integrations &#183; Website Design & Dev</p>
                                    </div>
                                    <div>
                                        <div class="slick-img-div">
                                            <div class="slick-img kmb-bg d-flex justify-content-center">
                                                <img class="slick-img" src={BR} />
                                            </div>

                                        </div>
                                        <div class="slick-second-section">
                                            <img class="kmb-logo" src={BRLogo} />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to="/asb" class="slider-inner-section">
                                    <div class="hi-index">
                                        <h4 class="mb-0 silder-title-text">Akanksha Singh Basketball</h4>
                                        <p class="para-mid grey-text">Content &#183; Integrations &#183; Website Design & Dev</p>
                                    </div>
                                    <div>
                                        <div class="slick-img-div">
                                            <div class="slick-img kmb-bg d-flex justify-content-center">
                                                <img class="kmb-img" src={AKS} />
                                            </div>

                                        </div>
                                        <div class="slick-second-section">
                                            <img class="kmb-logo" src={AKSLogo} />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to="/Printing" class="slider-inner-section">
                                    <div class="hi-index">
                                        <h4 class="mb-0 silder-title-text">Custom Printing </h4>
                                        <p class="para-mid grey-text">Stationery &#183; Journals &#183; Diaries &#183; Gifting
                                        </p>
                                    </div>
                                    <div>
                                        <div class="slick-img-div ">
                                            <div class="slick-img kmb-bg d-flex justify-content-center">
                                                <img class="slick-img" src={PrintP} />
                                            </div>

                                        </div>
                                        <div class="slick-second-section d-flex justify-content-center">
                                            <img class="kmb-logo ui" src={PdcLogo}
                                                style={{ width: "40%" }} />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to="/assetz" class="slider-inner-section">
                                    <div class="hi-index">
                                        <h4 class="mb-0 silder-title-text">Assetz</h4>
                                        <p class="para-mid grey-text">Presentations &#183; Brochures &#183;  Website Design & Dev
                                        </p>
                                    </div>
                                    <div>
                                        <div class="slick-img-div">
                                            <div class="slick-img kmb-bg d-flex justify-content-center">
                                                <img class="slick-img" src={Assetz} />
                                            </div>

                                        </div>
                                        <div class="slick-second-section">
                                            <img class="kmb-logo ui" src={AssetzLogo} />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to="/assetz_63" class="slider-inner-section">
                                    <div class="hi-index">
                                        <h4 class="mb-0 silder-title-text">63 Degree East</h4>
                                        <p class="para-mid grey-text"> Website Design & Dev &#183; Brochures &#183; Content</p>
                                    </div>
                                    <div>
                                        <div class="slick-img-div">
                                            <div class="slick-img kmb-bg d-flex justify-content-center">
                                                <img class="slick-img" src={Pro63} />
                                            </div>

                                        </div>
                                        <div class="slick-second-section">
                                            <img class="kmb-logo ui" src={Pro63Logo} />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to="/hereNow" class="slider-inner-section">
                                    <div class="hi-index">
                                        <h4 class="mb-0 silder-title-text">Here & Now</h4>
                                        <p class="para-mid grey-text"> Website Design & Dev &#183; Brochures</p>
                                    </div>
                                    <div>
                                        <div class="slick-img-div">
                                            <div class="slick-img kmb-bg d-flex justify-content-center">
                                                <img class="slick-img" src={HH} />
                                            </div>

                                        </div>
                                        <div class="slick-second-section">
                                            <img class="kmb-logo ui" src={HHLogo} />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to="/oak" class="slider-inner-section">
                                    <div class="hi-index">
                                        <h4 class="mb-0 silder-title-text">18 & Oak</h4>
                                        <p class="para-mid grey-text"> Website Design & Dev &#183; Brochures &#183; Content
                                        </p>
                                    </div>
                                    <div>
                                        <div class="slick-img-div">
                                            <div class="slick-img kmb-bg d-flex justify-content-center">
                                                <img class="slick-img" src={OCK} />
                                            </div>

                                        </div>
                                        <div class="slick-second-section">
                                            <img class="kmb-logo saf" src={OCKLogo} />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to="/ISA" class="slider-inner-section">
                                    <div class="hi-index">
                                        <h4 class="mb-0 silder-title-text">ISA</h4>
                                        <p class="para-mid grey-text">UX/UI &#183; Integrations &#183; Website Design & Dev

                                        </p>
                                    </div>
                                    <div>
                                        <div class="slick-img-div ">
                                            <div class="slick-img kmb-bg d-flex justify-content-center">
                                                <img class="slick-img" src={ISA} />
                                            </div>

                                        </div>
                                        <div class="slick-second-section d-flex justify-content-center">
                                            <img class="kmb-logo ui" src={ISALogo}
                                                style={{ width: "40%" }} />
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        </div>
                    </>}


                </div>
            </div>
            <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                <div class="d-flex justify-content-center" style={{ marginTop: "-20px" }}>
                    <button class="no-arr" onClick={handleLoadMore}>{loadMo ? "Load Less" : "Load More"}</button>
                </div>
            </div>
        </>
    );
}

export default MySlickCarousel;