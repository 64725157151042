/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react'
import GPSDesign from '../assets/Images/Icons/GreypathDesignLogo.svg'
import BackBox from '../assets/Images/Icons/BackBox.svg'
import Top from '../assets/Images/Projects/isa/ISA.jpg'
import Logo from '../assets/Images/logo/ISALOGO.png'
import bottom from '../assets/Images/Projects/isa/ISABOTTOM.jpg'
import ExternalArrow from '../assets/Images/Icons/external-arrow.png'
import IsaSlider from '../Components/IsaSlider'
import Footer from '../Components/FooterPro'
import { Link, useNavigate } from 'react-router-dom';

function useOak(probs) {
    const navigate = useNavigate();
    const scrollToServices = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("service") 
    };
    const scrollToBrand = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("brand")
    };
    const scrollToPrint = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("print")
    };
    const scrollToConsult = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("consult")
    };
    const scrollToApp = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("app")
    };
    const scrollToPro = () => {
        probs.handleButtonClick("home")
        probs.serviceHandle("pro")
    };
    const toHome = (val) => {
        navigate(`/?section=${val}`);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);
    return (
        <div className="project-page">
            <div class="bg-grey">
                <div class="container-fl navs bg-grey">
                    <div class="header-main d-flex justify-content-between">
                        <Link to="/" >
                            <img src={GPSDesign} class="primary-logo pointer dis-none" alt="image" />
                        </Link>


                        <div class="text-right">
                            <a class="nav-items dis-none" onClick={() => toHome("service")}>ABOUT</a>
                            <a class="nav-items dis-none" onClick={() => toHome("partner")}>PARTNERS</a>
                            <a class="nav-items dis-none" onClick={() => toHome("contact")}>CONTACT</a>
                        </div>
                        <button id="hiddenButton" style={{ display: "none" }}></button>

                    </div>
                    <div class="">
                        <div class="col col-xl-12">
                            <Link to="/" >
                                <img src={BackBox} alt="" width="32" height="32" class="mb-4 mb-lg-0 cursor-pointer" id="backButton" />
                            </Link>
                            <h1 class="project-title">ISA</h1>
                        </div>
                    </div>
                    <div class="col col-md-10 col-lg-8 col-xl-8 mt-nec-25 mb-lg-2">
                        <div class="dispaly-flex">
                            <button class="primary-btn bg-grey label-txt  mr-3 grey-text mb-2 ">UX</button>
                            <button class="primary-btn bg-grey label-txt mr-3 grey-text mb-2 ">Website Design & Dev</button>
                            <button class="primary-btn bg-grey label-txt mr-3 grey-text mb-2 ">Application Development</button>
                            <button class="primary-btn bg-grey label-txt  mr-3 grey-text mb-2">Functional Expertise</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="container-fl">
                    <div class="project-banner-section container-full">
                        <div class="pro-img-div">
                            <img src={Top} alt="" class="pro-img" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fl">
                <div class="container-fluid custom-fonts mt-70">
                    <div class="row">
                        <div class="col-sm-4 d-flex align-items-center justify-content-center">
                            <div
                                class="bg-grey text-center d-flex align-items-center justify-content-center logo-background">
                                <img src={Logo} alt="" class="projects_logo_img mb-4 mb-lg-0" loading="lazy"/>
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <p class="para-sm grey-text mx-5 mx-lg-0 mx-md-0 mb-lg-5">International Screenwriters Association champions screenwriters to Hollywood agents, managers and producers, all of who are seeking talent and the next big screenplay. The platform gets screenwriters noticed and helps advance their craft, connections and careers. The resources offered by the ISA are unparalled.
                            </p>
                            <div class="d-flex  project-bullet-list">
                                <div class="mr-15">
                                    <p class="para-mid grey-text">Functional Expertise</p>
                                    <p class="para-mid grey-text">Mind Maps</p>
                                    <p class="para-mid grey-text">Wireframes</p>
                                    <p class="para-mid grey-text">UX Design</p>
                                    <p class="para-mid grey-text">UI Implementation</p>
                                </div>
                                <div>
                                    <p class="para-mid grey-text">Website Development</p>
                                    <p class="para-mid grey-text">Application Development</p>
                                    <p class="para-mid grey-text">Micro-interactions</p>
                                    <p class="para-mid grey-text">API Integrations</p>
                                    <p class="para-mid grey-text">Continuous Buildout & Support</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <IsaSlider />

            <div class="">
                <div class="container-fl">
                    <div class="project-banner-section mt-70 container-full">
                        <div class="pro-img-div bots">
                            <img src={bottom} alt="" class="pro-img" loading="lazy"/>
                        </div>
                        <div>
                            <div class="d-flex justify-content-center">
                                <button class="project-big-button">
                                    <a href="https://www.networkisa.org/" target="_blank" rel="noreferrer">
                                        <p class="text-center white-text para-md dis-none visit_website_font">VISIT THE WEBSITE</p>
                                        <div
                                            class="d-flex d-sm-flex d-md-block d-lg-flex d-xl-flex text-center align-items-center justify-content-center">
                                            <h3 class="text-center white-text para-xl text-decor-unline">
                                                www.networkisa.org</h3>
                                            <img src={ExternalArrow} alt=""
                                                class="ml-3 external-aroww-img mb-3" loading="lazy" />
                                        </div>
                                    </a>
                                </button>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <Footer scrollToServices={scrollToServices} scrollToBrand={scrollToBrand} scrollToPrint={scrollToPrint}
                scrollToConsult={scrollToConsult} scrollToApp={scrollToApp} scrollToPro={scrollToPro} />
        </div>
    )
}
export default useOak