/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from 'react';
import BlackLogo from '../assets/Images/Icons/black_logo.svg'
import SubmitButton from '../assets/Images/Icons/SubmitButton.svg'
import LinkedInLogoGrey from '../assets/Images/Icons/LinkedInLogoGrey.svg'
import Insta from '../assets/Images/Icons/Instagram.svg'
import Gpsfull from '../assets/Images/Icons/logo-full-GreypathSolutions.svg'
import Pdc from '../assets/Images/Icons/PDCLOGO.svg'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from 'react-google-recaptcha';
import AlertBox from './AlertBox';import Select from "react-select";
import { useNavigate } from 'react-router-dom';
const customStyles = {
    indicatorSeparator: (base) => ({ ...base, display: "none" }),
    control: (base, state) => ({
      ...base,
      height:"48px",
      borderRadius: state.menuIsOpen ? "8px 8px 0 0" : 8,
      borderColor: state.isFocused ? "#2C2C2C" : "#B0B0B0",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "#2C2C2C" : "#B0B0B0"
      }
    }),placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            whiteSpace: 'nowrap',
        }
    },
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? "#f1f1f1" : "",
        color: state.isSelected ? "#212529" : "",
        padding:"12px"
      }),
      singleValue: (base) => ({
        ...base,
        fontSize: 24,
        color: "",
        margin: "0px",
      }),
      valueContainer: (base) => ({
        ...base,
        padding:"5px 12px 5px 12px",
      }),
      input: (base) => ({
        ...base,
        margin:"0px"
      }),
  };


const Footer = (probs) => {
    const navigate = useNavigate();
    const contactRef = useRef(null);
    const [print, setPrint] = useState(false)

    const [interested, setInterested] = useState("")
    const [weAre, setWeAre] = useState("")
    const [stage, setStage] = useState("")
    const [whereHere, setWhereHere] = useState("")

    const [help, setHelp] = useState("")
    const [name, setName] = useState("")
    const [mail, setMail] = useState("")
    const [number, setNumber] = useState("")
 
    const[alert,setAlert]=useState(false);
    const[message,setMessage]=useState("");
    const[type,setType]=useState("")

 const nameRef = useRef(null);
 const emailRef =useRef(null);
 const textRef =useRef(null);
 const numberRef =useRef(null);
 const interestedRef=useRef(null);
 const whereHereRef=useRef(null);
 const phoneRef =useRef(null);
 const submitRef=useRef(null);
 const [selectedInterested, setSelectedInterested] = useState(false);   
 const [isMenuOpenInterested, setIsMenuOpenInterested] = useState(false);
 const [selected, setSelected] = useState(false);   
 const [isMenuOpen, setIsMenuOpen] = useState(false);
 const [selectedwhatStage, setSelectedwhatStage] = useState(false);   
 const [isMenuOpenwhatStage, setIsMenuOpenwhatStage] = useState(false);
 const [selectedwhereDid, setSelectedwhereDid] = useState(false);   
 const [isMenuOpenwhereDid, setIsMenuOpenwhereDid] = useState(false);
 const weAreRef = useRef(null);
 const refwhatStage = useRef(null);
 const refwhereDid = useRef(null);
 const onMenuOpenInterested = () => setIsMenuOpenInterested(true);
 const onMenuCloseInterested = () => setIsMenuOpenInterested(false); 
 const onChangeInterested =(e) => {
    console.log(e);
     setInterested(e)
     setSelectedInterested(true);
     setIsMenuOpen(true);
     const selectEl = weAreRef.current;
     if (!selectEl) return;
     if (selectedInterested) selectEl.blur();
     else selectEl.focus();
 }
 const onMenuOpenwhatStage = () => setIsMenuOpenwhatStage(true);
 const onMenuClosewhatStage = () => setIsMenuOpenwhatStage(false); 
 const onChangewhatStage =(e) => {
    setStage(e)
     setSelectedwhatStage(true);
     setIsMenuOpenwhereDid(true);
    const selectEl = refwhereDid.current;
    if (!selectEl) return;
    if (selectedwhatStage) selectEl.blur();
    else selectEl.focus();
 }
const onMenuOpen = () => setIsMenuOpen(true);
const onMenuClose = () => setIsMenuOpen(false); 
const onChange=(e) => {
    setWeAre(e)
    setSelected(true);
    setIsMenuOpenwhatStage(true);
    const selectElwhatStage = refwhatStage.current;
    if (!selectElwhatStage) return;
    if (selected) selectElwhatStage.blur();
    else selectElwhatStage.focus();
}
const onMenuOpenwhereDid = () => setIsMenuOpenwhereDid(true);
const onMenuClosewhereDid = () => setIsMenuOpenwhereDid(false); 
const onChangewhereDid =(e) => {
    setWhereHere(e)
    setSelectedwhereDid(true);
    const selectEl = textRef.current;
    if (!selectEl) return;
    if (selectedwhereDid) selectEl.blur();
    else selectEl.focus();
}
const handleKeyDownTextarea = (e) =>{
    console.log(e);
    if (e.key === 'Enter') {
        nameRef.current.focus();
        e.preventDefault();
      }
}
const handleKeyDownName = (e) =>{
    if (e.key === 'Enter') {
        emailRef.current.focus();
        e.preventDefault();
      }
}
const handleKeyDownEmail = (e) =>{
    if (e.key === 'Enter') {
        phoneRef.current.numberInputRef.focus();
        e.preventDefault();
      }
}
const handleKeyDownPhoneno= (e) =>{
    if (e.key === 'Enter') {
        validateForm(e);
        e.preventDefault();
      }
}
 const recaptchaRef = React.createRef();
    const scrollToServices = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const toHome = (val) => {
        navigate(`/?section=${val}`);
    };
let error =[];

const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};


const clearForm = () => {
    setInterested("");
    setWeAre("");
    setStage("");
    setWhereHere("");
    setHelp("");
    setName("");
    setMail("");
    setNumber("");
  };

function validateForm(e) {
e.preventDefault();
console.log("test");
   if(name===""){
error.push("Name")
nameRef.current.style.border = '2px solid red';

    
   }else{

    nameRef.current.style.border = 'none';

   }

   if(help===""){
    error.push("Name")
      
    textRef.current.style.border = '2px solid red';
        
       }else{

        textRef.current.style.border = 'none';
       }

       
   if(interested===""){
    error.push("interested")
      console.log("hi");
      interestedRef.current.controlRef.style.border = '2px solid red';
        
       }else{

        interestedRef.current.controlRef.style.border = 'none';
       }

       if(weAre===""){
        error.push("weAre")
        
        weAreRef.current.controlRef.style.border = '2px solid red';
            
           }else{
    
            weAreRef.current.controlRef.style.border = 'none';
           }
       if(stage===""){
        error.push("stage")
        
        refwhatStage.current.controlRef.style.border = '2px solid red';
            
           }else{
    
            refwhatStage.current.controlRef.style.border = 'none';
           }
       if(whereHere===""){
        error.push("whereHere")
        
        refwhereDid.current.controlRef.style.border = '2px solid red';
            
           }else{
    
            refwhereDid.current.controlRef.style.border = 'none';
           }

   if(mail===""){
    error.push("mail")
   
    emailRef.current.style.border = '2px solid red';
   }
   
   if(mail!==""){
 
     if(!validateEmail(mail)){
    error.push("mail")
  
    emailRef.current.style.border = '2px solid red';

   }else{

    emailRef.current.style.border = 'none';


   }

   }
  

   if(number==="" || number.length !==12){


    error.push("number")
    phoneRef.current.numberInputRef.parentElement.style.border='2px solid red';
    phoneRef.current.numberInputRef.parentElement.style.borderRadius='10px';
   }else{

    phoneRef.current.numberInputRef.parentElement.style.border="none";

   }


   if(error.length===0){

    handleMail();
   }

   error=[];








    
}





   
const handleMail = async() => {
      
    const recaptchaToken = await recaptchaRef.current.executeAsync();

    const requestBody ={
        Name:name,
        Email: mail,
        mobileNumber: number,
        Weareinterestedin: interested?.value,
        Weare: weAre?.value,
        WhatStageareyouat: stage?.value,
        Wheredidyouhearaboutus: whereHere?.value,
        Howcanwehelp: help,
        recaptchaToken:recaptchaToken
    }

    recaptchaRef.current.reset();
   


    
    fetch('https://greypath.design:4040/send-email', {
method: "POST",
headers: {
    'Content-Type': 'application/json',
    
    
},



body:JSON.stringify(requestBody)
})
.then(res => {

  if (res.ok) {
     setMessage('Thanks For Your Information')
     setType('success')
    setAlert(true);
 
    clearForm();
    return res.json(); 
   }
})
.then(data => {
console.log(data);
})
.catch(error => {
console.error('There was a problem with the fetch operation:', error);
setMessage('Please try again later')
setType('error')
setAlert(true);
});

}


    useEffect(() => {
        const pageName = window.location.pathname.split('/').pop();
        if (pageName === "printing") {
            setPrint(true)
        }
        else {
            setPrint(false)
        }
        return () => {

        };
    }, []);
    return (
        <>
            <div ref={probs.footerRef}>
                <div className='container-fl mt-70' id="contact" ref={contactRef}>
                    <div className="custom-fonts">
                        <div>
                            <h1>Got a project in mind<br />
                                Talk to us</h1>
                        </div>
                        <div className="mt-70 dispaly-flex">
                            <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
                                <p className="para-ms mb-0">+91 80 413 20073</p>
                                <p className="para-ms">info@greypathsolutions.com</p>
                                <p className="para-mid mt-4 mt-md-4 mt-lg-5 mt-xl-5">Greypath<br />
                                    1st Floor, Four Squares, <br />
                                    85, Haudin Road,<br />
                                    Halasuru, Bengaluru<br />
                                    KA 560042, INDIA</p>
                                <div className="mt-70 mb-4">
                                    <a className='pointer' href="https://greypathsolutions.com/" target="_blank" rel="noreferrer">
                                        <img src={BlackLogo} className="primary-logo pointer" alt="image" loading="lazy"/>
                                    </a>

                                </div>
                            </div>
                            <div>
                                <form onSubmit={validateForm}  ref={submitRef}>
                                <div className="d-flex">
                                    <div className="footer-box">
                                        <div>
                                            <div className="mb-4 d-block d-sm-block d-md-flex d-lg-flex d-xl-flex">
                                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 pr-0 pl-0">
                                                    <p className="label mb-1 pl-3">WE ARE INTERESTED IN</p>
                                                    <Select styles={customStyles} options={[
                                                    { value: 'UX/UI', label: 'UX/UI' },
                                                    { value: 'Branding', label: 'Branding' },
                                                    { value: 'Content', label: 'Content' },
                                                    { value: 'Website', label: 'Website' },
                                                    { value: 'Presentations', label: 'Presentations' },
                                                    { value: 'Stationery Design', label: 'Stationery Design' },
                                                    { value: 'Printing Services', label: 'Printing Services' },
                                                    { value: 'Marketing', label: 'Marketing' },
                                                    { value: 'Consulting', label: 'Consulting' },
                                                    { value: 'Others', label: 'Others' }
                                                    ]} 
                                                    onMenuOpen={onMenuOpenInterested}
                                                    onMenuClose={onMenuCloseInterested} value={interested} ref={interestedRef} onChange={onChangeInterested}placeholder="SELECT AN OPTION" id="interested" />

                                                </div>
                                                <div
                                                    className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pr-0 mt-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0 pl-0 pl-sm-0 pl-md-3 pl-lg-3 pl-xl-3">
                                                    <p className="label mb-1 pl-3">WE ARE</p>
                                                    <Select styles={customStyles} options={[
                                                    { value: 'Agency', label: 'An Agency' },
                                                    { value: 'SME', label: 'An MSME' },
                                                    { value: 'Corporate', label: 'Corporate' },
                                                    { value: 'start-up', label: 'A start-up' },
                                                    { value: 'Individuals', label: 'Individuals' },
                                                    { value: 'Others', label: 'Others' },
                                                    ]} 
                                                    ref={weAreRef}
                                                    menuIsOpen={isMenuOpen}
                                                    onMenuOpen={onMenuOpen}
                                                    onMenuClose={onMenuClose} value={weAre} onChange={onChange} placeholder="SELECT OPTION" id="weAre" />
                                                </div>
                                            </div>


                                            <div className="mb-4">
                                                <p className="label mb-1 pl-3">WHAT STAGE ARE YOU AT</p>
                                                <Select styles={customStyles} options={[
                                                        { value: 'Have detailed requirements', label: 'Have detailed requirements' },
                                                        { value: 'Need help with our business requirement document', label: 'Need help with our business requirement document' },
                                                        { value: 'We already have wireframes/designs', label: 'We already have wireframes/designs' },
                                                        { value: 'Others', label: 'Others' },
                                                        ]} 
                                                        ref={refwhatStage}
                                                        menuIsOpen={isMenuOpenwhatStage}
                                                        onMenuOpen={onMenuOpenwhatStage}
                                                        onMenuClose={onMenuClosewhatStage} value={stage} onChange={onChangewhatStage} placeholder="SELECT AN OPTION" id="whatStage" />
                                            </div>
                                            <div className="mb-4">
                                                <p className="label mb-1 pl-3">WHERE DID YOU HEAR ABOUT US</p>
                                                <Select styles={customStyles} options={[
                                                            { value: 'Search engine', label: 'Your Company (enter NA if you are an individual)' },
                                                            { value: 'Referral', label: 'Referral' },
                                                            { value: 'Social Media', label: 'Social Media' },
                                                            { value: 'Partner website', label: 'Partner website' },
                                                            { value: 'Other', label: 'Others' },
                                                            ]} 
                                                            ref={refwhereDid}
                                                            menuIsOpen={isMenuOpenwhereDid}
                                                            onMenuOpen={onMenuOpenwhereDid}
                                                            onMenuClose={onMenuClosewhereDid} value={whereHere} onChange={onChangewhereDid} placeholder="SELECT AN OPTION" id="whereDid" />
                                            </div>
                                            <div className="mb-4">
                                                <p className="label mb-1 pl-3">HOW CAN WE HELP?</p>
                                                <textarea ref={textRef} className="customs-textarea" name='text' placeholder="THIS IS A TEXT INPUT FIELD"
                                                    id="howHelp" rows="5"
                                                    value={help} onChange={(e) => setHelp(e.target.value)} onKeyDown={handleKeyDownTextarea}
                                                ></textarea>
                                            </div>
                                            <div className="mb-4">
                                                <p className="label mb-1 pl-3">YOUR NAME</p>
                                                <input ref={nameRef} type="text"  className={`custom-input`} placeholder="FIRST MIDDLE LAST"
                                                    id="name" name="Name"
                                                    value={name} onChange={(e) => setName(e.target.value)} onKeyDown={handleKeyDownName}
                                                />
                                                <div id="name-error" className="error-message"></div>
                                            </div>

                                            <div className="dispaly-flex justify-content-md-between">
                                                <div className="mb-4 col-md-6 pl-0 pr-0 pr-sm-0 pr-md-3 pr-lg-3 pr-xl-3">
                                                    <p className="label mb-1 pl-3">YOUR EMAIL</p>
                                                    <input ref={emailRef} type="text" className="custom-input" placeholder="email@email.com"
                                                        id="email"
                                                        value={mail} name="email" onChange={(e) => setMail(e.target.value)}
                                                        onKeyDown={handleKeyDownEmail} />
                                                    <div id="email-error" className="error-message"></div>
                                                </div>
                                                <div className="mb-4 col-md-6 col-sm-12 pr-0 pl-0 pl-sm-0 pl-md-3 pl-lg-3 pl-xl-3">
                                                    <p className="label mb-1 pl-3">YOUR PHONE NUMBER</p>
                                                    {/* <input type="text" id="phoneNumber" className="custom-input" placeholder="+91"
                                                        value={number} onChange={(e) => setNumber(e.target.value)} /> */}
                                                    <PhoneInput
                                                           autocompleteSearch={false}
                                                           className=""
                                                           country={"in"}
                                                           value={number}
                                                           countryCodeEditable={false}
                                                        onChange={(phone) => setNumber(phone)}
                                                        ref={phoneRef}
                                                        onKeyDown={handleKeyDownPhoneno}
                                                    />

                                                       { <div ref={numberRef} style={{display:"none"}} id="phone-error" className="error-message">This field is required</div>}
                                              
                                                </div>

                                            </div>
                                            <div className="dispaly-flex justify-content-between pr-3">
                                                <div className="col-md-8 col-sm-12 pr-0 pl-0">
                                                    <p className="label-foot mb-0 mt-2 pt-1">This form collects your contact
                                                        information
                                                        so
                                                        that we
                                                        can
                                                        correspond with you. Check out our privacy policy to protect and
                                                        manage
                                                        your
                                                        data.</p>
                                                        <div className='col-md-8 col-sm-12 pr-0 pl-0'>
                                                 <ReCAPTCHA
      ref={recaptchaRef}
      size="invisible"
      sitekey="6LeUPd4pAAAAAN-6rbvU4DBoNG8QObz-8_yPBo6B"
    
    />   
                                                </div>
                                                </div>
                                                { alert&&<AlertBox message={message} type={type} />}
                                                <div
                                                    className="col-md-4 col-sm-12 mt-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0 pl-0 pl-sm-0 pl-md-3 pl-lg-3 pl-xl-3 cursor-pointer">
                                                    <div className="d-flex align-items-center" id="submit-btn">
                                                    <button style={{all:'unset'}} type='submit'><h4 className="foot white-text mb-0 mt-3 mr-3">Submit <span><img src={SubmitButton} className="submit-arrow"
                                                            alt="image" loading="lazy"/></span></h4>
                                                       </button>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    {/* {cookieWrap ? <>
                                        {cookieAlert ?
                                            <div id="cookieAlert">
                                                <div
                                                    className="cookie-alert ml-lg-3 ml-md-0 cook-disp d-none d-sm-none d-md-none d-lg-none d-xl-block">
                                                    <span className="" style={{ fontSize: "14px" }}>This website uses cookies. </span><a
                                                        href="#" className="text-white" onclick="showLearnMore()">Learn more</a>
                                                    <button type="button" className="close text-white" aria-label="Close"
                                                        onClick={handleAlert}>
                                                        <img src={White_close} alt="" aria-hidden="true"
                                                            className="close-button" />
                                                    </button>
                                                </div>

                                            </div>
                                            : ""}

                                    </> : ""} */}



                                    {/* <div id="scrollToTop" className='' >
                                        <img src={Scrolltotop} alt="" width="40" height="40" />
                                    </div> */}
                                </div></form>
                                <div className="mt-5 d-block d-sm-block d-md-block d-lg-none d-xl-none">
                                    <div className="d-block d-sm-flex d-md-flex justify-content-between">
                                        <div>
                                            <p className="para-ms mb-0">+91 80 413 20073</p>
                                            <p className="para-ms">info@greypathsolutions.com</p>
                                        </div>
                                        <div>
                                            <p className="para-mid mt-4 mt-sm-0 mt-md-0 mt-md-0 mt-lg-5 mt-xl-5">Greypath<br />
                                                1st Floor, Four Squares,
                                                85, Haudin Road,<br />
                                                Halasuru, Bengaluru<br />
                                                KA 560042, INDIA</p>
                                        </div>

                                    </div>


                                    <div className="mt-70">
                                        <img src={BlackLogo} className="gps-logo" alt="image" loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-grey">
                    <div className="container-fl navs bg-grey mt-neg-390">
                        <div className="dispaly-flex justify-content-between custom-fonts">
                            <div className="dispaly-flex">
                                <div className="mr-5">
                                    <ul>
                                        <li className="cursor-pointer" onClick={() => toHome("service")}>Website Design</li>
                                        <li className="cursor-pointer" onClick={() => toHome("brand")}>Branding</li>
                                        <li className="cursor-pointer" onClick={() => toHome("app")}>Presentations</li>
                                        <li className="cursor-pointer" onClick={() => toHome("print")}>Printing Services</li>
                                        <li className="cursor-pointer" onClick={() => toHome("consult")}>Consulting Services</li>

                                    </ul>
                                </div>
                                <div className="font-weight-500">
                                    <ul>
                                        <li className="fw-500 cursor-pointer" onClick={() => toHome("pro")}>Projects +</li>
                                        <li className="fw-500 cursor-pointer" onClick={() => toHome("service")}>About Us</li>
                                        <li className="fw-500 cursor-pointer" onClick={() => toHome("service")}>Services</li>

                                        <li className="fw-500 cursor-pointer" onClick={scrollToServices}>Contact</li>

                                    </ul>
                                </div>

                            </div>

                            <div className="d-flex d-sm-block d-md-block d-lg-block d-xl-block mt-4 mt-sm-4 mt-lg-0 mt-xl-0">
                                <div className="mb-2 mb-lg-0 disp-xxl pr-3 pr-sm-5 pr-md-0 pr-lg-0 pr-xl-0">
                                    <a className='pointer' href="https://greypathsolutions.com/" target="_blank" rel="noreferrer">
                                        <img src={Gpsfull} alt="" width="119"
                                            height="53" className=" footer-logo-background" loading="lazy"/>
                                        <p className="footer-nav grey-text mt-2 ml-1">Coding & Development</p>
                                    </a>

                                </div>
                                <div className="disp-xxl pr-3 pr-sm-5 pr-md-0 pr-lg-0 pr-xl-0">
                                    <a className='pointer' href="https://www.paperdesignco.com/" target="_blank" rel="noreferrer">
                                        <img src={Pdc} alt="" width="119" height="53"
                                            className="footer-logo-background" loading="lazy"/>
                                        <p className="footer-nav grey-text mt-2 ml-1">Paper Design Company</p>
                                    </a>
                                </div>

                            </div>




                        </div>
                        <div className="linked mt-0">
                            <div className='d-flex  align-items-center justify-content-start justify-content-lg-start justify-content-md-start justify-content-sm-start justify-content-xl-end'>
                                {/* <p className="footer-nav grey-text mb-0 pt-2 d-none d-sm-none d-md-none d-lg-none d-xl-block">Text if required</p> */}
                                <div className='text-left text-md-left text-sm-left text-lg-left text-xl-right'>
                                    <div className='flex '>
                                        {print ? <>
                                            <a href="https://www.linkedin.com/company/paper-design-co/" target="_blank" rel="noreferrer">
                                                <img src={LinkedInLogoGrey} alt="" width="30" height="30"
                                                    className="ml-0 ml-sm-0 ml-md-0 ml-lg-0 ml-xl-2  cursor-pointer" loading="lazy"/>
                                            </a>
                                        </> :

                                            <>
                                                <a href="https://www.linkedin.com/company/greypath-solutions/" target="_blank" rel="noreferrer">
                                                    <img src={LinkedInLogoGrey} alt="" width="30" height="30"
                                                        className="ml-0 ml-sm-0 ml-md-0 ml-lg-0 ml-xl-2  cursor-pointer" loading="lazy" />
                                                </a>
                                            </>}

                                        {print ? <>
                                            <a href="https://www.instagram.com/paperdesigncompany/" target="_blank" rel="noreferrer">
                                                <img src={Insta} alt="" width="30" height="30" style={{ opacity: "70%" }}
                                                    className="ml-3 cursor-pointer" loading="lazy" />
                                            </a>
                                        </> : ""}

                                    </div>
                                    {print ? <>
                                        <p className="footer-nav grey-text mb-0 mt-2 d-block">info@paperdesignco.com</p>
                                    </> : <>
                                        <p className="footer-nav grey-text mb-0 mt-2 d-block">info@greypathsolutions.com</p>
                                    </>}

                                </div>



                            </div>

                        </div>


                        <div className="dispaly-flex custom-fonts mt-70 foot pt-3 footer-bottom footer-bot-bg">
                            <p className="mr-auto grey-text footer-nav mt-lg-2 mt-2"><span style={{ fontSize: "14px" }}>&#169;</span>
                                2024
                                Greypath</p>
                            
                        </div>

                    </div>
                </div>
            </div>

        </>





    )
}
export default Footer