import React, { createContext, useState } from 'react'

export const AccordianContext =createContext();




export const AppProvider = ({children}) => {
const[title,setTitle]=useState("")


  return (
    <AccordianContext.Provider value={{title:title,"setTitle":setTitle}}>{children}</AccordianContext.Provider>)
}



