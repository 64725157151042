import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Home from './Pages/Home';
import Kmb from './Pages/KMB';
import Oak from './Pages/18&oak';
import Asb from './Pages/Asb'
import Assetz_63 from './Pages/Assetz_63'
import Assetz from './Pages/Assetz'
import Br from './Pages/br'
import Hn from './Pages/Hn';
import Lapalazzo from './Pages/lapalazzo';
import LendEast from './Pages/lend_east'
import SAF from './Pages/SAF'
import UIC from './Pages/UIC'
import ISA from './Pages/Isa'
import Printing from './Pages/Printing'



const pageVariants = {
  initial: {
    opacity: 1,
    x: '100vw',
  },
  in: {
    opacity: 1,
    x: '0',

  },
  out: {
    opacity: 1,
    x: '0',

  },
};

const pageTransition = {
  type: 'tween',
  // ease: 'anticipate',
  duration: 0.5,
};

export default function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <Home />
          </motion.div>
        } />
        <Route path="/kmb" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
           

          >
            <Kmb />
          </motion.div>
        } />
        <Route path="/oak" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <Oak />
          </motion.div>
        } />
        <Route path="/asb" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <Asb />
          </motion.div>
        } />
        <Route path="/assetz_63" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <Assetz_63 />
          </motion.div>
        } />
        <Route path="/assetz" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <Assetz />
          </motion.div>
        } />
        <Route path="/brigadeResidences" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <Br />
          </motion.div>
        } />
        <Route path="/hereNow" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <Hn />
          </motion.div>
        } />
        <Route path="/Lapalazzo" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <Lapalazzo />
          </motion.div>
        } />
        <Route path="/Lapalazzo" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <Lapalazzo />
          </motion.div>
        } />
        <Route path="/Lendeast" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <LendEast />
          </motion.div>
        } />
        <Route path="/SAF" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <SAF />
          </motion.div>
        } />
        <Route path="/UIC" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <UIC />
          </motion.div>
        } />
        <Route path="/ISA" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <ISA />
          </motion.div>
        } />
          <Route path="/printing" element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}

          >
            <Printing />
          </motion.div>
        } />
      </Routes>
    </AnimatePresence>
  );
}