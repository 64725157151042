/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext } from 'react';
import ServiceWeb from '../assets/Images/Shapes/services-websites.png'
import BrandingWeb from '../assets/Images/Shapes/services-branding.svg'
import PrintWeb from '../assets/Images/Shapes/services-printing.png'
import consultWeb from '../assets/Images/Shapes/services-consulting.svg'
import ArrowBlackUp from '../assets/Images/Icons/Arrow-black-up.svg'
import ArrowGreyDown from '../assets/Images/Icons/Arrow-grey-down.svg'
import { Link, useNavigate } from 'react-router-dom';
import { AccordianContext } from './Context/AppProvider';
const AccordionItem = ({ title, content, image, reff, onToggle, isOpen }) => {

    console.log("open",isOpen);
    return (
        <div className={`accordion-item ${isOpen ? 'open' : 'closed'}`} ref={reff}>
            <button className="accordion-toggle" onClick={onToggle}>
                <div className="d-flex justify-content-between cursor-pointer">
                    <p className={`para-head mb-2 mt-4 ${isOpen ? 'ac-black-text' : 'ac-grey-text'}`}>{title}</p>
                    <img className="external-aroww-img accor mt-4" src={isOpen ? ArrowBlackUp : ArrowGreyDown} alt="Arrow" loading="lazy"/>
                </div>
            </button>
            <div className="accordion-content" style={{ display: isOpen ? 'block' : 'none' }}>
                <div className="black-bg">
                    {content}
                </div>
            </div>
        </div>
    );
};

const MyAccordion = (probs) => {
    const [openAccordion, setOpenAccordion] = useState(''); // Default to first item open

    const title=useContext(AccordianContext);

    console.log("title ac",title);
        useEffect(()=>{
    
            setOpenAccordion(title.title);
            console.log("op",openAccordion);

        },[title.title])
    const handleAccordionToggle = (title) => {
        setOpenAccordion(openAccordion === title ? '' : title); // Toggle or close
    };
    return (
        <div className="accordion">
            <AccordionItem
                title="WEBSITES"
                reff={probs.serviceMobRef}
                isOpen={openAccordion === 'WEBSITES'}
                onToggle={() => handleAccordionToggle('WEBSITES')}
                content={
                    <>

                        <div className='inside-div'>
                        </div>
                        <div className="d-sm-block d-md-flex d-lg-flex inside-div pt-2" ref={probs.serviceMobRef}>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 pr-sm-2 pr-md-5 pr-lg-5">
                                <p class="para-msx white-text">Good website design is the cornerstone of a successful online presence. We set about understanding your audience and delivering an intuitive, visually appealing experience that drives engagement and achieves your objectives. From clear navigation and responsive design to consistent branding and fast loading speeds, we prioritize every detail to ensure your website not only looks great but also delivers results.
                                    <span className='d-md-none'>With our user-centric approach and attention to accessibility, we create websites that captivate audiences, enhance credibility, and drive meaningful interactions. Let us help you craft a website that stands out in today's digital landscape and leaves a lasting impression on your visitors.
                                        <br />
                                        <br />
                                        <a class="cursor-pointer" onClick={probs.scrollToFooter}><p
                                                class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">
                                                Get in Touch &rsaquo;</p>
                                        </a>
                                    </span>
                                </p>
                                <p class="para-msx white-text mb-0 mt-5">1. 100% custom designed websites
                                </p>
                                <p class="para-msx white-text mb-0">2. Responsive & mobile friendly design & development
                                </p>
                                <p class="para-msx white-text mb-0">3. SEO optimised design</p>
                                <p class="para-msx white-text mb-0">4. Fast loading & performance focused design
                                </p>
                                <p class="para-msx white-text mb-0">5. Advanced interactions</p>
                                <p class="para-msx white-text mb-0">6. Ongoing support & maintainance
                                </p>

                                <p class="para-mix white-text mt-5">WEBSITE SERVICES</p>
                                <p class="para-mid white-text mt-4 mb-1 font-weight-normal">Website Audit
                                </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Website/Digital Strategy</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Documentation
                                </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">UX Strategy & Workshops</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Mind Maps & Wireframes
                                </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Website UI/Visual Design</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Front-end Development (HTML-CSS, React)</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Back-end Development </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">API Integrations</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Website Deployment & Launch</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Cloud Hosting Services</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Support & Maintenance </p>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 pt-4 pt-md-0 pt-lg-0 d-none d-sm-none d-md-block">
                                <p class="para-msx white-text">With our user-centric approach and attention to accessibility, we create websites that captivate audiences, enhance credibility, and drive meaningful interactions. Let us help you craft a website that stands out in today's digital landscape and leaves a lasting impression on your visitors.
                                    <br />
                                    <br />
                                    <a class="cursor-pointer" onClick={probs.scrollToFooter}>
                                        <p
                                            class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">
                                            Get in Touch &rsaquo;</p>
                                    </a>
                                </p>
                                <img class="mt-5 mb-5 inside-img d-none d-sm-none d-md-block roll-img" src={ServiceWeb} loading="lazy"/>
                            </div>
                        </div>
                    </>
                }
                image={ServiceWeb}
            />
            <AccordionItem
                title="BRANDING"
                reff={probs.brandMobRef}
                isOpen={openAccordion === 'BRANDING'}
                onToggle={() => handleAccordionToggle('BRANDING')}
                content={
                    <>
                        <div className='inside-div'>
                        </div>
                        <div className="d-sm-block d-md-flex d-lg-flex inside-div pt-2" ref={probs.brandMobRef}>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 pr-sm-2 pr-md-5 pr-lg-5">
                                <p class="para-msx white-text">We specialize in creating impactful visual identities that capture the essence of your brand be it in real estate, fin-tech, retail/ecommerce or technology. Along with our design team collaborators, who come with over 15 years of industry experience, we focus on understanding your unique story and translating it into captivating design elements. From defining your brand's personality to establishing consistent digital style guides, we help you leave a lasting impression in today's competitive landscape.
                                    <span className='d-md-none'>We also create impactful presentations for our clients that resonate with the intended audiences and drive results. From engaging info graphics and customizable templates, to refining presentation flows and strategizing pitch approaches, our expertise is tailored to meet your specific needs.
                                        <br />
                                        <br />
                                        <a class="cursor-pointer" onClick={probs.scrollToFooter}>
                                            <p
                                                class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">
                                                Get in Touch &rsaquo;</p>
                                        </a>
                                    </span>

                                </p>


                                <p class="para-mix white-text mt-5">BRANDING SERVICES</p>
                                <p class="para-mid white-text mt-4 mb-1 font-weight-normal">Identity
                                </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Logo Design</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Concepts
                                </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Brand Guidelines</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Digital Style Guides</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Custom Presentation Templates </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Infographic Design</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Stationery
                                </p>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 pt-4 pt-md-0 pt-lg-0 d-none d-sm-none d-md-block">
                                <p class="para-msx white-text">we focus on understanding your unique story and translating it into captivating design elements. From defining your brand's personality to establishing consistent digital style guides, we are committed to helping you leave a lasting impression in today's competitive landscape.
                                    <br />
                                    <br />
                                    <a class="cursor-pointer" onClick={probs.scrollToFooter}>
                                        <p
                                            class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">
                                            Get in Touch &rsaquo;</p>
                                    </a>
                                </p>
                                <img class="mt-5 mb-5 inside-img d-none d-sm-none d-md-block roll-img"
                                    src={BrandingWeb} loading="lazy"/>
                            </div>
                        </div>
                    </>
                }
                image={BrandingWeb}
            />
           
            <AccordionItem
                title="PRINTING"
                reff={probs.printMobRef}
                isOpen={openAccordion === 'PRINTING SERVICES'}
                onToggle={() => handleAccordionToggle('PRINTING SERVICES')}
                content={
                    <>
                        <div className='inside-div'>
                        </div>
                        <div className="d-sm-block d-md-flex d-lg-flex inside-div pt-2" ref={probs.printMobRef}>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 pr-sm-2 pr-md-5 pr-lg-5">
                                <p class="para-msx white-text">We are your destination for paper, printing, and stationery solutions. Along with our partners we specialize in custom designs, professional marketing materials, and seamless printing coordination. Explore our online services for high-quality,<span className='d-md-none'>
                                    customized products including visiting cards, books, diaries, and more. From order to production to shipping across India, we ensure a hassle-free experience. Contact us today to elevate your printing needs effortlessly!
                                    <br />
                                    <br />
                                    <a class="cursor-pointer" onClick={probs.scrollToFooter}>
                                        <p
                                            class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">
                                            Get in Touch &rsaquo;</p>
                                    </a>
                                </span>

                                </p>




                                <p class="para-mix white-text mt-5">PRINTING SERVICES</p>
                                <p class="para-mid white-text mt-4 mb-1 font-weight-normal">Coffee Table Books
                                </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Wedding Books</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Diaries/Planners
                                </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Photo Albums</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Calendars</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Visiting cards </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Wedding Cards </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Wedding Stationery </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Corporate Stationery</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Envelopes</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Bespoke Gifting</p>

                                <div class="mt-4 mb-4">
                                <Link class="cursor-pointer" to="/printing">
                                    <p
                                        class="para-mix mt-4 text-decoration-underline orange-text org-bot d-inline mb-1">
                                        Learn More &rsaquo;</p>
                                </Link>

                            </div>
                            </div>

                            
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 pt-4 pt-md-0 pt-lg-0 d-none d-sm-none d-md-block">
                                <p class="para-msx white-text">customized products including visiting cards, books, diaries, and more. From order to production to shipping across India, we ensure a hassle-free experience. Contact us today to elevate your printing needs effortlessly!
                                    <br />
                                    <br />
                                    <a class="cursor-pointer" href="https://greypathsolutions.com/"
                                        target="_blank" rel="noreferrer">
                                        <p
                                            class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">
                                            Get in Touch &rsaquo;</p>
                                    </a>
                                </p>
                                <img class="mt-5 mb-5 inside-img d-none d-sm-none d-md-block roll-img"
                                    src={PrintWeb} loading="lazy"/>
                            </div>
                        </div>
                    </>
                }
                image={BrandingWeb}
            />

            <AccordionItem
                title="CONSULTING"
                reff={probs.printMobRef}
                isOpen={openAccordion === 'CONSULTING SERVICES'}
                onToggle={() => handleAccordionToggle('CONSULTING SERVICES')}
                content={
                    <>
                        <div className='inside-div'>                        </div>
                        <div className="d-sm-block d-md-flex d-lg-flex inside-div pt-2">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 pr-sm-2 pr-md-5 pr-lg-5">
                                <p class="para-msx white-text">Our consulting services serve as your guiding light in the most fundamental business areas setup's, re-structure, operations, tech enablement and solutions. Backed by our sister company, a seasoned technology solutions and services provider with two decades of experience, we bring a wealth of expertise to the table. Our founders backgrounds, rooted in technology, foundational setups, greenfield projects, operations and business process enhancements, lays the groundwork for our consulting services. We believe in simplicity and practicality, focusing on real-world solutions tailored to your unique needs.<span className='d-md-none'>From strategic planning to operational improvements, our approach is centered on driving tangible results and sustainable growth. Partner with us to navigate complexities, harness opportunities, and pave the way for your business's success.
                                    <br />
                                    <br />
                                    <a class="cursor-pointer" onClick={probs.scrollToFooter}>
                                        <p
                                            class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">
                                            Get in Touch &rsaquo;</p>
                                    </a>

                                </span>


                                </p>


                                <p class="para-mix white-text mt-5">CONSULTING & ADVISORY</p>
                                <p class="para-mid white-text mt-4 mb-1 font-weight-normal">Start-up Setup
                                </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Restructure & Streamline</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Specialised Team Setup</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Operationalising Teams</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Business Process Improvements</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Tech Infrastructure Setup</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Tech Solutions & Enablement</p>

                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 pt-4 pt-md-0 pt-lg-0 d-none d-sm-none d-md-block">
                                <p class="para-msx white-text"> From strategic planning to operational improvements, our approach is centered on driving tangible results and sustainable growth. Partner with us to navigate complexities, harness opportunities, and pave the way for your business's success.
                                    <br />
                                    <br />
                                    <a class="cursor-pointer" href="https://greypathsolutions.com/"
                                        target="_blank" rel="noreferrer">
                                        <p
                                            class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">
                                            Get in Touch &rsaquo;</p>
                                    </a>
                                </p>
                                <img class="mt-5 mb-5 inside-img d-none d-sm-none d-md-block roll-img"
                                    src={consultWeb} loading="lazy"/>
                            </div>
                        </div>
                    </>
                }
                image={BrandingWeb}
            />

            {/* <AccordionItem
                title="APPLICATION DEVELOPMENT"
                isOpen={openAccordion === 'PRESENTATIONS'}
                onToggle={() => handleAccordionToggle('PRESENTATIONS')}
                content={
                    <>
                        <div className='inside-div'>
                            <p class="para-mix white-text service-pad mb-0 mt-2 mt-md-4">COMPELLING PRESENTATION SOLUTIONS</p>
                        </div>
                        <div className="d-sm-block d-md-flex d-lg-flex inside-div pt-2">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 pr-sm-2 pr-md-5 pr-lg-5">
                                <p class="para-mix white-text mt-5">COMPELLING PRESENTATION SOLUTIONS</p>
                                <p class="para-msx white-text"> <span className='d-md-none' >
                                     
                                    <br />
                                    <br />
                                    <a class="cursor-pointer" onClick={probs.scrollToFooter}>
                                        <p
                                            class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">
                                            Get in Touch &rsaquo;</p>
                                    </a>
                                </span></p>
                                <p class="para-mix white-text mt-5">PRESENTATIONS</p>
                                <p class="para-mid white-text mt-4 mb-1 font-weight-normal">Content
                                </p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Custom Templates</p>
                                <p class="para-mid white-text mb-1 font-weight-normal">Infographic Design
                                </p>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 pt-4 pt-md-0 pt-lg-0 d-none d-sm-none d-md-block">
                                <p class="para-msx white-text">we leverage our collective experience to create high-impact presentations for fundraising initiatives, new venture pitches, product ideas and other critical endeavors. Count on us to elevate your presentations and make a lasting impression on your audience.
                                    <br />
                                    <br />
                                    <a class="cursor-pointer" onClick={probs.scrollToFooter}>
                                        <p
                                            class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">
                                            Get in Touch &rsaquo;</p>
                                    </a>
                                </p>
                                <img class="mt-5 mb-5 inside-img d-none d-sm-none d-md-block roll-img"
                                    src={SolutionWeb} loading="lazy" />
                            </div>
                        </div>
                    </>
                }
                image={BrandingWeb}
            /> */}


        </div>
    );
};

export default MyAccordion;