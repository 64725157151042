import $ from 'jquery';
import 'slick-carousel';
export function initializeScrollAndClickBehaviors() {
    const servicesElement = document.getElementById('servicesMost');
    const topPart = document.querySelector('.top-part');
    const buffer = 500;

    window.addEventListener('scroll', () => handleScroll(servicesElement, topPart, buffer));
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', handleAnchorClick);
    });
}

// Handle scroll behavior
function handleScroll(servicesElement, topPart, buffer) {
    const scrollPosition = window.scrollY;
    const servicesTop = servicesElement.offsetTop;
    const servicesBottom = servicesTop + servicesElement.offsetHeight - buffer;

    
}

// Handle anchor click behavior
function handleAnchorClick(e) {
    e.preventDefault();
    document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
    });
}

// Function to initialize tab behavior
export function initializeTabBehavior() {
    const tabs = document.querySelectorAll('.tabs');
    tabs.forEach(tab => {
        tab.addEventListener('click', () => setActiveTab(tab.id));
    });

    window.addEventListener('scroll', () => handleTabScroll());
    setActiveTab('tab1'); // Set the first tab as active initially
}

// Set active tab
function setActiveTab(activeTabId) {
    const tabs = document.querySelectorAll('.tabs');
    tabs.forEach(tab => {
        if (tab.id === activeTabId) {
            tab.classList.add('active-tab');

            tab.querySelector('.external-aroww-img').src = process.env.PUBLIC_URL + '/Icons/Arrow.svg';
        } else {
            tab.classList.remove('active-tab');

            tab.querySelector('.external-aroww-img').src = process.env.PUBLIC_URL + '/Icons/Arrow-grey.svg';
        }
    });
}


function handleTabScroll() {
    const sections = ['web', 'brand', 'print', 'consult', 'app'];
    let found = false;

    for (let i = sections.length - 1; i >= 0; i--) {
        const section = document.getElementById(sections[i]);
        if (isElementNearTopOfViewport(section)) {
            setActiveTab('tab' + (i + 1));
            found = true;
            break;
        }
    }

    if (!found) {
        setActiveTab('tab1');
    }
}


export function isElementNearTopOfViewport(el) {
    if (!el) return false; // Element does not exist

    const rect = el.getBoundingClientRect();
    const offset = 150;
    return rect.top < offset && rect.bottom > 0;
}
export const initializeAccordion = () => {
    document.querySelectorAll('.accordion-toggle').forEach(toggle => {
        toggle.addEventListener('click', function () {
            const content = this.nextElementSibling;
            const isOpen = this.classList.contains('open');

            // Close other open items
            document.querySelectorAll('.accordion-content').forEach(item => {
                if (item !== content) {
                    item.style.display = 'none';
                    item.previousElementSibling.classList.remove('open');
                    item.previousElementSibling.classList.add('closed');
                    // item.previousElementSibling.querySelector('.para-head').style.color = '#A9A7A4';
                    item.previousElementSibling.querySelector('.external-aroww-img').src = process.env.PUBLIC_URL + '/Icons/Arrow-grey-down.svg';
                }
            });

            // Toggle current item
            if (isOpen) {
                this.classList.remove('open');
                this.classList.add('closed');
                // this.querySelector('.para-head').style.color = '#A9A7A4';
                this.querySelector('.external-aroww-img').src = process.env.PUBLIC_URL + '/Icons/Arrow-grey-down.svg';
                content.style.display = 'none';
            } else {
                this.classList.add('open');
                this.classList.remove('closed');
                // this.querySelector('.para-head').style.color = 'black';
                this.querySelector('.external-aroww-img').src = process.env.PUBLIC_URL + '/Icons/Arrow-black-up.svg';
                content.style.display = 'block';
            }
        });
    });
};
export function scrollToSection(section) {

    var screenWidth = window.innerWidth;
    var sectionId = screenWidth <= 1200 ? section + '-mob' : section;


    const element = document.getElementById(sectionId);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });


        if (screenWidth <= 1200) {
            var accordionContent = document.querySelector('#' + sectionId + ' .accordion-content');
            if (accordionContent) {
                accordionContent.style.display = 'block';
            }
        }
    }
}

export function toggleLoadMoreContent() {
    const loadMoreButton = document.getElementById('loadMorebtn');
    const contentToToggle = document.getElementById('loadMore');
    const contentToHide = document.getElementById('load-none');

    loadMoreButton.addEventListener('click', function () {
   
        // Check if content is currently hidden
        if (contentToToggle.classList.contains('d-none')) {
            contentToToggle.classList.remove('d-none');
            contentToToggle.style.display = 'block'; // Optional based on your CSS
            contentToHide.style.display = 'none';
            loadMoreButton.textContent = 'Load Less'; // Change button text to 'Load Less'
        } else {
            contentToToggle.classList.add('d-none');
            contentToToggle.style.display = 'none';
            contentToHide.style.display = 'block';
            loadMoreButton.textContent = 'Load More'; // Change button text back to 'Load More'
        }
    });
}
export function sliders() {
    $('.slider').slick({
        dots: false,
        infinite: true, 
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '280px',
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    infinite: true,
                    arrows: true,
                    centerMode: true,
                    centerPadding: '100px',
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    infinite: true,
                    arrows: true,
                    centerMode: true,
                    centerPadding: '70px',
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    infinite: true,
                    arrows: true,
                    centerMode: true, // Change to true for iPad
                    centerPadding: '25px',
                    slidesToShow: 1
                }
            },
           
        ]
    });
}
export const initializeScrollToTop = () => {
    const handleScroll = () => {
        const scrollToTopBtn = document.getElementById("scrollToTop");
        if (window.pageYOffset > 20) {
            scrollToTopBtn.style.display = "block";
        } else {
            scrollToTopBtn.style.display = "none";
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    window.addEventListener('scroll', handleScroll);

    const scrollToTopBtn = document.getElementById("scrollToTop");
    if (scrollToTopBtn) {
        scrollToTopBtn.addEventListener('click', scrollToTop);
    }

    // Optional: Return a cleanup function to remove event listeners
    return () => {
        window.removeEventListener('scroll', handleScroll);
        if (scrollToTopBtn) {
            scrollToTopBtn.removeEventListener('click', scrollToTop);
        }
    };
};