/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef } from 'react'
import DesignLogo from "../assets/Images/Icons/GreypathDesignLogo.svg"
import PrintIll from "../assets/Images/bg/printing-illustration.svg"
import Back from "../assets/Images/Icons/BackBox.svg"
import PdcLogo from "../assets/Images/Icons/PDCLOGO.svg"
import Rectangle from "../assets/Images/bg/PDCSIL1.jpg"
import RectangleCopy from "../assets/Images/bg/PDCSIL2.jpg"
import Rectangle2 from "../assets/Images/bg/PDCSIL3.jpg"

import Rectangle3 from "../assets/Images/bg/PDCSIL4.jpg"
import Rectangle4 from "../assets/Images/bg/PDCSIL5.jpg"
import Rectangle5 from "../assets/Images/bg/PDCSIL1.jpg"

import WhitLogo from "../assets/Images/Icons/pdc-whitelogo.svg"
import Rectanglex from "../assets/Images/bg/GiftBox.jpg"

import Rectangle6 from "../assets/Images/bg/PDCSIL7.jpg"
import Rectangle7 from "../assets/Images/bg/PDCSIL4.jpg"
import Footer from "../Components/FooterPro"
import { Link, useNavigate } from 'react-router-dom';
function Printing() {
    const navigate = useNavigate();
    const footerRef = useRef(null);

    const toHome = (val) => {
        navigate(`/`);
    };
    const scrollToFooter = () => {
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);
    return (
        <>

            <div class="printing-page project-page" style={{ overflow: "hidden" }}>
                <div class="container-fl navs bg-grey">
                    <div class="header-main d-flex justify-content-between">
                        <Link to="/" >
                            <img src={DesignLogo} class="primary-logo pointer dis-none" alt="image" />
                        </Link>

                        <button id="hiddenButton" style={{ display: "none" }}></button>

                    </div>
                    <div>
                        <div class="position-relative">
                            <div class="col col-xl-12">
                                <div onClick={() => toHome("print")} >
                                    <img src={Back} alt="" width="32" height="32"
                                        class="mb-4 mb-lg-0 cursor-pointer" id="backButton" />
                                </div>
                                <h1 class="project-title mb-5">Custom Printing </h1>
                                <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                                    <img src={PrintIll} alt="" width="70%" height="100%"
                                        class="illustrative-img-res" />
                                </div>
                                <div>
                                    <div class="printing-para mb-lg-5 mb-5">Personal, Corporate or Weddings – Elevate Your Stationery  Experience.</div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="col-sm-12 col-md-8 col-lg-8 px-0 ">
                                        <div class="para-smller">
                                            We are your destination for paper, printing, and design solutions. We specialize in custom designs, professional marketing materials, and seamless printing coordination. Explore our online services for high-quality, customized products including visiting cards, books, diaries, and more. For anything bespoke or custom just drop us a line. From order to production to shipping across India, we ensure a hassle-free experience. &nbsp;
                                            <p class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1 cursor-pointer" onClick={scrollToFooter}>Get in touch now ›</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4">
                                        <div className=''>
                                            <img src={PrintIll} alt="" width="" height=""
                                                class="dis-none illustrative-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="https://www.paperdesignco.com/" target="_blank" rel="noreferrer">
                                <div class="explaination-box d-flex align-items-center justify-content-center mb-lg-5 mt-5">
                                    <img src={PdcLogo} alt="" width="100" height="54" />
                                    <p class="ml-auto para-md text-decor-unline mt-0 mt-lg-2 dis-none black_text dis-none-xl">
                                        Learn
                                        more about Paper Design Company</p>
                                </div>
                            </a>
                        </div>

                    </div>

                </div>
                <div class="container-fl mt-70" style={{ position: "relative", zIndex: "-1" }}>
                    <div class="d-block d-sm-block d-md-flex d-lg-flex d-xl-flex">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 pl-0 pr-0 pr-sm-0 pr-md-2 pr-lg-2 pr-xl-2">
                            <div className='print-img-div mb-4'>
                                <img src={Rectangle}
                                    class="print-img " loading="lazy"/>
                            </div>
                            <div className='print-img-div mb-4'>

                                <img src={Rectanglex}
                                    class="print-img " loading="lazy"/>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 pr-0 pl-0 pl-sm-0 pl-md-2 pl-lg-2 pl-xl-2">
                            <div className='print-img-div mb-4 mb-3 mb-xl-4'>
                                <img src={RectangleCopy}
                                    class="print-img "  loading="lazy"/>
                            </div>
                            <div className='print-img-div mb-3 mb-xl-4 d-none d-md-none d-lg-block'>
                                <img src={Rectangle2}
                                    class="print-img" loading="lazy"/>
                            </div>
                            <div className='print-img-div mb-3 mb-xl-4 d-none d-md-none d-lg-block' style={{ paddingBottom: "53%" }}>
                                <img src={Rectangle3}
                                    class="print-img" loading="lazy"/>
                            </div>
                            <div className='print-img-div mb-4 d-block d-md-block d-lg-none d-xl-none'>
                                <img src={Rectangle5}
                                    class="print-img" loading="lazy"/>
                            </div>
                        </div>
                    </div>
                    <div class="d-block d-sm-block d-md-flex d-lg-flex d-xl-flex">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 pl-0 pr-0 pr-sm-0 pr-md-2 pr-lg-2 pr-xl-2">
                            <div className='print-img-div mb-3 mb-xl-4'>
                                <img src={Rectangle4}
                                    class="print-img" loading="lazy"/>
                            </div>
                            <div className='print-img-div mb-4 d-none d-md-none d-lg-block d-xl-block'>
                                <img src={Rectangle5}
                                    class="print-img" loading="lazy" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 pr-0 pl-0 pl-sm-0 pl-md-2 pl-lg-2 pl-xl-2">
                            <div className='print-img-div mb-4 d-none d-md-none d-lg-block d-xl-block' style={{ paddingBottom: "102.5%" }}>
                                <img src={Rectangle6}
                                    class="mb-4 print-img" loading="lazy"/>
                            </div>
                            <div className='print-img-div mb-4'>
                                <img src={Rectangle7}
                                    class="print-img mb-4 object-fit-cover d-block d-sm-block d-md-block d-lg-none d-xl-none" loading="lazy" />
                            </div>
                        </div>

                    </div>
                </div>
                <div className='pdc-top'>
                    <a href="https://www.paperdesignco.com/" target="_blank" rel="noreferrer">
                        <div class="pdc-white-logo" href="https://www.paperdesignco.com/">
                            <div class="d-flex justify-content-center">
                                <img src={WhitLogo} alt="" loading="lazy"/>
                            </div>

                        </div>
                    </a>

                </div>


                <p class="para-md text-center learn-more-para mt-3" href="https://www.paperdesignco.com/">Learn more about
                    Paper Design Company</p>
            </div>
            <Footer footerRef={footerRef} />
        </>
    )

}
export default Printing