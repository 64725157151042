import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderX1 from '../assets/Images/Projects/assets_63/63SIL1.jpg'
import sliderx2 from '../assets/Images/Projects/assets_63/63SIL2.jpg'
import Slider_3 from '../assets/Images/Projects/assets_63/63SIL3.jpg'
import Slider_5 from '../assets/Images/Projects/assets_63/63SIL5.jpg'
const SlickSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '280px',
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    infinite: true,
                    arrows: true,
                    centerMode: true,
                    centerPadding: '100px',
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    infinite: true,
                    arrows: true,
                    centerMode: true,
                    centerPadding: '70px',
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    infinite: true,
                    arrows: true,
                    centerMode: true, 
                    centerPadding: '25px',
                    slidesToShow: 1
                }
            },
        ]
    };


    return (
        <div class="container-fl ">
            <div class="project-silder mt-70 container-full">
                <Slider {...settings}>
                    <div>
                        <img src={sliderX1} alt="img" className="slider-img" loading="lazy"/>
                    </div>
                    <div>
                        <img src={sliderx2} alt="img" className="slider-img" loading="lazy"/>
                    </div>
                    <div>
                        <img src={Slider_3} alt="img" className="slider-img" loading="lazy"/>
                    </div>
                    <div>
                        <img src={Slider_5} alt="img" class="slider-img" loading="lazy"/>
                    </div>
                    

                </Slider>
            </div>

        </div>
    );
};

export default SlickSlider;