import React, { useEffect, useRef } from 'react';
import MobileView from './Test'
import Arrow from '../assets/Images/Icons/Arrow.svg'
import Arrowgrey from '../assets/Images/Icons/Arrow-grey.svg'
import ServiceWeb from '../assets/Images/Shapes/services-websites.png'
import BrandingWeb from '../assets/Images/Shapes/services-branding.svg'
import PrintWeb from '../assets/Images/Shapes/services-printing.png'
import consultWeb from '../assets/Images/Shapes/services-consulting.svg'
import { Link } from 'react-router-dom';

import { initializeScrollAndClickBehaviors, initializeTabBehavior, initializeAccordion, scrollToSection, isElementNearTopOfViewport } from '../custom';
const Services = (probs) => {
    const ref = useRef(null);
    useEffect(() => {
        initializeScrollAndClickBehaviors();
        initializeTabBehavior();
        initializeAccordion();
        scrollToSection()
        if (ref.current) {
            const isNearTop = isElementNearTopOfViewport(ref.current);
            console.log(isNearTop);
        }

    }, []);

    return (
        <>
            <div class='container-fl ani-div' id="servicesMost" ref={probs.serviceRef}>
                <div class="custom-fonts d-none d-xs-none d-sm-none d-md-none d-lg-none d-xl-block">
                    <div class="dispaly-flex">
                        <div class="col col-sm-12 col-md-4 mt-4 pr-lg-0 pr-xl-4" >
                            <div class="top-part" ref={ref} className={`sticky-part wid-28`}>
                                <h3 class="xlx mt-4 pt-2">Our Services</h3>
                                <div class="tabs active-tab mt-5 col-12 pl-0 pr-0  cursor-pointer" id="tab1" >
                                    <a class="d-flex justify-content-between" href="#web">
                                        <p class="para-head mb-2">WEBSITES</p>
                                        <img class="external-aroww-img  arr-disp" src={Arrow} loading="lazy" />
                                    </a>

                                </div>
                                <div class=" tabs mt-5 col-12 pl-0 pr-0  cursor-pointer" id="tab2">
                                    <a class="d-flex justify-content-between" href="#brand">
                                        <p class="para-head mb-2">BRANDING</p>
                                        <img class="external-aroww-img arr-disp" src={Arrowgrey} loading="lazy" />
                                    </a>

                                </div>
                                
                                <div class=" tabs mt-5 col-12 pl-0 pr-0   cursor-pointer" id="tab3">
                                    <a class="d-flex justify-content-between" href="#print">
                                        <p class="para-head mb-2">PRINTING</p>
                                        <img class="external-aroww-img arr-disp" src={Arrowgrey}  loading="lazy"/>
                                    </a>
                                </div>
                                <div class="tabs mt-5 col-12 pl-0 pr-0  cursor-pointer" id="tab4">
                                    <a class="d-flex justify-content-between" href="#consult">
                                        <p class="para-head mb-2">CONSULTING</p>
                                        <img class="external-aroww-img arr-disp" src={Arrowgrey} loading="lazy" />
                                    </a>

                                </div>
                                 {/* <div class="tabs mt-5 col-12 pl-0 pr-0 cursor-pointer" id="tab5">
                                    <a class="d-flex justify-content-between" href="#app">
                                        <p class="para-head mb-2">APPLICATION DEVELOPMENT</p>
                                        <img class="external-aroww-img arr-disp" src={Arrowgrey} />
                                    </a>

                                </div>       */}

                            </div>


                        </div>
                        <div class="black-bg ani-div col col-sm-12 col-md-8 common-div mt-4">
                            <div class="thumbnail-component black-bg pb-4 mt-4 pt-5" id="web">
                                <div className='inside-div'>
                                </div>

                                <div class="d-flex inside-div">
                                    <div class="col-6 pr-5">

                                        <p class="para-msx white-text">Good website design is the cornerstone of a successful online presence. We set about understanding your audience and delivering an intuitive, visually appealing experience that drives engagement and achieves your objectives. From clear navigation and responsive design to consistent branding and fast loading speeds, we prioritize every </p>
                                        <p class="para-msx white-text mb-0 mt-5">1. 100% custom designed websites

                                        </p>
                                        <p class="para-msx white-text mb-0">2. Responsive & mobile friendly design & development</p>
                                        <p class="para-msx white-text mb-0">3. SEO optimised design</p>
                                        <p class="para-msx white-text mb-0">4. Fast loading & performance focused design</p>
                                        <p class="para-msx white-text mb-0">5. Advanced interactions</p>
                                        <p class="para-msx white-text mb-0">6. Ongoing support & maintainance</p>

                                        <p class="para-mix white-text mt-5">WEBSITE SERVICES</p>
                                        <p class="para-mid white-text mt-4 mb-1 font-weight-normal">Website Audit </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Website/Digital Strategy</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Documentation</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">UX Strategy & Workshops
                                        </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Mind Maps & Wireframes</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Website UI/Visual Design</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Front-end Development (HTML-CSS, React) </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Back-end Development </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">API Integrations</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Website Deployment & Launch</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Cloud Hosting Services</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Support & Maintenance</p>

                                    </div>
                                    <div class="col-6">
                                        <p class="para-msx white-text">detail to ensure your website not only looks great but also delivers results. With our user-centric approach and attention to accessibility, we create websites that captivate audiences, enhance credibility, and drive meaningful interactions. Let us help you craft a website that stands out in today's digital landscape and leaves a lasting impression on your visitors.
                                            <br />
                                            <br />
                                            <a class="cursor-pointer" onClick={probs.scrollToFooter}>
                                                <p class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">Get in Touch ›</p></a>
                                        </p>
                                        <img class="mt-5 mb-5 inside-img" src={ServiceWeb} loading="lazy"/>
                                    </div>

                                </div>

                            </div>
                            <div class="thumbnail-component black-bg mt-5" id="brand" ref={probs.brandRef}>
                                <div className='inside-div '>
                          
                                </div>

                                <div class="d-flex inside-div">
                                    <div class="col-6 pr-5">
                                      
                                        <p class="para-msx white-text">We specialize in creating impactful visual identities that capture the essence of your brand be it in real estate, fin-tech, retail/ecommerce or technology. Along with our design team collaborators, who come with over 15 years of industry experience, we focus on understanding your unique story and translating it into captivating design elements. From defining your brand's personality to establishing consistent digital style guides, we help you leave a lasting impression in </p>


                                     
                                        <p class="para-mid white-text mt-4 mb-1 font-weight-normal">Identity</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Logo Design</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Concepts</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Brand Guidelines </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Digital Style Guides</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Custom Presentation Templates </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Infographic Design</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Stationery </p>

                                    </div>
                                    <div class="col-6">
                                        <p class="para-msx white-text">today's competitive landscape. We also create impactful presentations for our clients that resonate with the intended audiences and drive results. From engaging info graphics and customizable templates, to refining presentation flows and strategizing pitch approaches, our expertise is tailored to meet your specific needs.
                                            <br />
                                            <br />
                                            <a class="cursor-pointer" onClick={probs.scrollToFooter}><p class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">Get in Touch ›</p></a>
                                        </p>
                                        <img class="mt-5 mb-5 inside-img" src={BrandingWeb} loading="lazy"/>
                                    </div>

                                </div>
                            </div>
                           
                            <div class="thumbnail-component black-bg" ref={probs.printRef} id="print">
                                <div className='inside-div mt-6'>
                                </div>

                                <div class="d-flex inside-div"  >
                                    <div class="col-6 pr-5" >


                                        <p class="para-msx white-text">We are your destination for paper, printing, and stationery solutions. Along with our partners we specialize in custom designs, professional marketing materials, and seamless printing coordination. Explore our online services for high-quality,</p>


                                        <p class="para-mix white-text mt-5">PRINTING SERVICES</p>
                                        <p class="para-mid white-text mt-4 mb-1 font-weight-normal">Coffee Table Books</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Wedding Books</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Diaries/Planners</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Photo Albums</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Calendars</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Visiting cards
                                        </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Wedding Cards
                                        </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Wedding Stationery
                                        </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Corporate Stationery
                                        </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Envelopes</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Bespoke Gifting
                                        </p>
                                        <div class="mt-4">
                                            <Link class="cursor-pointer" to="/printing">
                                                <p
                                                    class="para-mix mt-4 text-decoration-underline orange-text org-bot d-inline mb-1">
                                                    LEARN MORE &rsaquo;</p>
                                            </Link>

                                        </div>



                                    </div>
                                    <div class="col-6">
                                        <p class="para-msx white-text"> customized products including visiting cards, books, diaries, and more. From order to production to shipping across India, we ensure a hassle-free experience. Contact us today to elevate your printing needs effortlessly!
                                        </p>
                                        <br></br>
                                        <a class="cursor-pointer" onClick={probs.scrollToFooter}><p class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">Get in Touch ›</p></a>

                                        <img class="mt-5 mb-5 inside-img" src={PrintWeb} loading="lazy" />
                                    </div>

                                </div>
                            </div>
                            <div class="thumbnail-component black-bg" id="consult" ref={probs.consultRef}>
                                <div className='inside-div mt-6'>
                                </div>
                                <div class="d-flex inside-div">
                                    <div class="col-6 pr-5">

                                        <p class="para-msx white-text">Our consulting services serve as your guiding light in the most fundamental business areas setup's, re-structure, operations, tech enablement and solutions. Backed by our sister company, a seasoned technology solutions and services provider with two decades of experience, we bring a wealth of expertise to the table. Our founders backgrounds, rooted in technology, foundational setups, greenfield projects, operations and business process enhancements, lays the groundwork for our consulting services.</p>


                                        <p class="para-mix white-text mt-5">CONSULTING & ADVISORY</p>
                                        <p class="para-mid white-text mt-4 mb-1 font-weight-normal">Start-up Setup</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Restructure & Streamline</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Specialised Team Setup </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Operationalising Teams

                                        </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Business Process Improvements</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Tech Infrastructure Setup </p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Tech Solutions & Enablement</p>

                                    </div>
                                    <div class="col-6">
                                        <p class="para-msx white-text">We believe in simplicity and practicality, focusing on real-world solutions tailored to your unique needs. From strategic planning to operational improvements, our approach is centered on driving tangible results and sustainable growth. Partner with us to navigate complexities, harness opportunities, and pave the way for your business's success.
                                            <br />
                                            <br />
                                            <a class="cursor-pointer" onClick={probs.scrollToFooter}><p class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">Get in Touch ›</p></a>
                                        </p>
                                        <img class="mt-5 mb-5 inside-img" src={consultWeb} loading="lazy" />
                                    </div>

                                </div>

                            </div>
                        {/* <div class="thumbnail-component black-bg" id="app" ref={probs.appRef}>
                                <div className='inside-div '>
                                    <p class="para-mix white-text service-pad mb-0">COMPELLING PRESENTATION SOLUTIONS</p>
                                </div>
                                <div class="d-flex inside-div">
                                    <div class="col-6 pr-5">

                                        <p class="para-msx white-text"> </p>


                                        <p class="para-mix white-text mt-5">PRESENTATIONS</p>
                                        <p class="para-mid white-text mt-4 mb-1 font-weight-normal">Content</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Custom Templates</p>
                                        <p class="para-mid white-text mb-1 font-weight-normal">Infographic Design</p>

                                    </div>
                                    <div class="col-6">
                                        <p class="para-msx white-text">
                                            <br />
                                            <br />
                                            <a class="cursor-pointer" onClick={probs.scrollToFooter}><p class="para-mix mt-4 text-decoration-underline cln-text cln-bot d-inline mb-1">Get in Touch ›</p></a>
                                        </p>
                                        <img src={SolutionWeb} class="mt-5 mb-5 inside-img" alt="Logo" loading="lazy" />
                                    </div>

                                </div>

                            </div> */}




                        </div>

                    </div>


                </div>
                <div class="custom-fonts mt-4 d-xs-block d-sm-block d-md-block d-lg-block d-xl-none" id="services-mob">
                    <h3 class="xlx">Our Services</h3>
                    <MobileView serviceRef={probs.serviceRef} brandRef={probs.brandRef} printRef={probs.printRef}
                        serviceMobRef={probs.serviceMobRef} brandMobRef={probs.brandMobRef} printMobRef={probs.printMobRef} scrollToFooter={probs.scrollToFooter}/>



                </div>

            </div>
        </>
    )
}
export default Services