import React, { useRef, useEffect, useState, useContext } from 'react'
import Footer from '../Components/Footer'
import Services from '../Components/Services'
import TopHome from '../Components/TopHome'
import Slider from '../Components/Slider'
import LeftIllustration from '../assets/Images/Shapes/LeftIllustration.svg'
import RightIllustration from '../assets/Images/Shapes/RightIllustration.svg'
import LogoE1 from '../assets/Images/logo/E1LOGO.png'
import LogoGps from '../assets/Images/logo/GPSLOGO.svg'
import Vertyx from '../assets/Images/logo/Vertacx-logo.svg'
import Pdc from '../assets/Images/logo/logo-pdc.svg'
import LogoWhiteSpace from '../assets/Images/logo/logo-white-space.svg'
import {AppProvider } from '../Components/Context/AppProvider'

function Home(probs) {
    const [screen, setScreen] = useState(1200)
    const serviceRef = useRef(null);
    const serviceMobRef = useRef(null);
    const partnerRef = useRef(null);
    const footerRef = useRef(null);
    const brandRef = useRef(null);
    const brandMobRef = useRef(null);
    const printRef = useRef(null);
    const printMobRef = useRef(null);
    const consultRef = useRef(null);
    const appRef = useRef(null);
    const proRef = useRef(null);
    const proMobRef = useRef(null);

   

    useEffect(() => {
        const scrollPositionX = sessionStorage.getItem('scrollPositionX');
        const scrollPositionY = sessionStorage.getItem('scrollPositionY');
        if (scrollPositionX || scrollPositionY) {
            window.scrollTo(parseInt(scrollPositionX), parseInt(scrollPositionY));
        }
    }, []);


    const scrollToServices = () => {
       
        if (screen < 768) {
           
            serviceRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        else {
       
            serviceMobRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToPartner = () => {
        partnerRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToFooter = () => {
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToBrand = () => {

        if (screen < 1200) {
            brandMobRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        else {
            brandRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToPrint = () => {
        if (screen < 1200) {
            printMobRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        else {
            printRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToConsult = () => {
        consultRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToApp = () => {
        appRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToPro = () => {
        if (screen < 768) {
            proMobRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        else {
            proRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    useEffect(() => {
      
        const screenWidth = window.innerWidth;
        setScreen(screenWidth)
        const queryString = window.location.search;
        const queryParams = new URLSearchParams(queryString);
        const param1 = queryParams.get('section');
        if (param1 !== null && param1 === "service") {
            setTimeout(() => {
                scrollToServices();
            }, 500)

        }
        else if (param1 !== null && param1 === "brand") {
            setTimeout(() => {
                scrollToBrand();
            }, 500)
        }
        else if (param1 !== null && param1 === "print") {
            setTimeout(() => {
                scrollToPrint()
            }, 500)
        }
        else if (param1 !== null && param1 === "pro") {
            setTimeout(() => {
                scrollToPro()
            }, 500)
        }
        else if (param1 !== null && param1 === "partner") {
            setTimeout(() => {
                scrollToPartner()
            }, 500)
        }
        else if (param1 !== null && param1 === "contact") {
            setTimeout(() => {
                scrollToFooter()
            }, 500)
        }
        else if (param1 !== null && param1 === "consult") {
            setTimeout(() => {
                scrollToConsult()
            }, 500)
        }
        else if (param1 !== null && param1 === "app") {
            setTimeout(() => {
                scrollToApp()
            }, 500)
        }

        return () => {
        };
    }, [probs.ser, screen]);


    return (
        <>
            {/* <Test /> */}  <AppProvider>
            <TopHome scrollToServices={scrollToServices} scrollToPartner={scrollToPartner} scrollToFooter={scrollToFooter} />
            <Slider handleButtonClick={probs.handleButtonClick} proRef={proRef} proMobRef={proMobRef} />
            <Services serviceRef={serviceRef} serviceMobRef={serviceMobRef} brandRef={brandRef} brandMobRef={brandMobRef} printRef={printRef} printMobRef={printMobRef} consultRef={consultRef} appRef={appRef} scrollToFooter={scrollToFooter} />

            <div className='container-fl how-we-work'>
                <div className="image-container">
                    <img src={LeftIllustration} className="half-image shadow-1"
                        alt="GPS" loading="lazy" />
                </div>
                <div className="image-container-right">
                    <img src={RightIllustration} className="half-image" alt="GPS" loading="lazy" />
                </div>
                <div className="custom-fonts mt-5">
                    <h3>Discover the impact of collaborative expertise across all our offerings.</h3>

                    <div className="dispaly-flex mt-3 mt-xl-5 pt-4 ">
                        <div className="col-sm-12 col-md-12 offset-lg-1 col-lg-2 pl-0 pl-sm-0 pl-md-5 pl-lg-5 pl-xl-5">
                            <p className="para-head mb-4 pb-2">HOW WE WORK</p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8 pl-0 pl-sm-0 pl-md-5 pl-lg-5 pl-xl-5">
                            <p className="para-small">At Greypath, we know collaborating works. Our team comprises of dedicated in-house members and consultative subject matter experts, selected based on their experience, quality, innovative thinking, and proven track record. What sets us apart is our rigorous evaluation process for all consultative individuals and teams. We work closely with them to ensure they meet our standards before engaging in any project. </p>
                            <p className="para-small">
                                <b>But that's just the beginning,</b> as we and our collaborators offer advisory and consulting services along with a strong ability to execute and implement. From strategic guidance to practical solutions, we provide comprehensive support that drives tangible results throughout your journey.
                            </p>
                            <p className="para-small">Founded by industry veterans with over 30-years of cross-industry experience, Greypath is your one-stop solution for all your digital, consulting, technology and printing needs. </p>

                        </div>

                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="image-container-sm mb-4">
                            <img src={LeftIllustration} className="half-image-1 shadow-1"
                                alt="GPS" loading="lazy" />
                        </div>
                        <div className="image-container-sm-rt mb-4">
                            <img src={RightIllustration} className="half-image-1"
                                alt="GPS" loading="lazy"/>
                        </div>

                    </div>

                    <div id="partners" ref={partnerRef}></div>
                    <div className="dispaly-flex">
                        <div className="col-sm-12 col-md-12 offset-lg-1 col-lg-2 mb-4 pl-0 pl-sm-0 pl-md-5 pl-lg-5 pl-xl-5">
                            <p className="para-head collbrator-div bck"></p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8 pl-0 pl-sm-0 pl-md-5 pl-lg-5 pl-xl-5 pr-0">
                            
                            <div className="d-flex align-items-center flex-wrap">
                                <div className="mt-0 mt-md-5 mt-lg-5 mt-xl-5 mr-3 mr-sm-4 mr-md-4 mr-lg-4 mr-xl-4">
                                    <img src={LogoE1} className="logos" alt="GPS" loading="lazy"/>
                                </div>
                                <div className="mt-0 mt-md-5 mt-lg-5 mt-xl-5 mr-3 mr-sm-4 mr-md-4 mr-lg-4 mr-xl-4">
                                    <a  href="https://greypathsolutions.com/" target="_blank">
                                    <img src={LogoGps} className="logos" alt="GPS" loading="lazy"/>
                                    </a>
                                   
                                </div>
                                
                                <div className="mt-0 mt-md-5 mt-lg-5 mt-xl-5 mr-3 mr-sm-4 mr-md-4 mr-lg-4 mr-xl-4">
                                    <img src={Vertyx} className="logos" alt="logo" loading="lazy" />
                                </div>
                                <div className="mt-0 mt-md-5 mt-lg-5 mt-xl-5">
                                    <a href="https://www.paperdesignco.com/" target="_blank">
                                        <img src={Pdc} className="logos" alt="PDC" loading="lazy" />
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dispaly-flex  align-items-center mt-5" onClick={scrollToFooter}>
                        <div className="col-sm-12 col-md-12  offset-lg-1 col-lg-2 pl-5">
                            <p className="para-head collbrator-div"></p>
                        </div>
                        <div className="col-12 col-sm-10 col-md-10 col-lg-6 col-xl-custom work-with-us cursor-pointer ml-0 ml-sm-0 ml-md-5 ml-lg-5 ml-xl-5"
                            data-target="contact">
                            <div className="work-with-us-display align-items-center">
                                <div className="pos-relative">
                                    <img src={LogoWhiteSpace} className="logos" alt="GPS" loading="lazy" />
                                    <div className="plus-symbol">+</div>
                                </div>
                                <div className="ml-0 ml-sm-4 ml-md-4 ml-lg-4 ml-xl-4 opt-50">
                                    <h4 className="mb-0 mt-4 mt-md-0 mt-lg-0 mt-xl-0">Work with us</h4>
                                    <p className="para-mid grey-text mb-0">Ready to collaborate? Let’s join forces and elevate client  &nbsp;
                                        <span className="d-sm-block d-md-inline responsive-newline"> 
                                        experiences  together.
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dispaly-flex  align-items-center mt-5 mb-0 mb-sm-0 mb-md-5">
                        <div className="col-sm-12 col-md-12 offset-lg-1 col-lg-2 ">
                            <p className="para-head collbrator-div"></p>
                        </div>
                        <a href="https://greypathsolutions.com/" target="_blank"
                            className="mt-4 bor-bottom d-none d-sm-block d-md-block d-lg-block d-xl-block ml-0 ml-sm-0 ml-md-5 ml-lg-5 ml-xl-5 ">
                            <p className="gps para-mid Calibre-Semibold mb-0">LEARN MORE ABOUT GREYPATH TECHNOLOGY &rsaquo;</p>
                        </a>
                    </div>

                </div>
            </div>

            <Footer footerRef={footerRef} scrollToServices={scrollToServices} scrollToBrand={scrollToBrand} scrollToPrint={scrollToPrint}
                scrollToConsult={scrollToConsult} scrollToApp={scrollToApp} scrollToPro={scrollToPro} />
</AppProvider>
        </>
    )
}
export default Home