
import React, { useEffect, useState } from 'react'
import './assets/bootstrap/bootstrap.css'
import './style.scss';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AnimatedRoutes from './AnimatedRoutes';
import Scrolltotop from './assets/Images/Icons/Scrolltotop.svg'

function App() {
  const [showButton, setShowButton] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 20) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <BrowserRouter>
      <AnimatedRoutes />
      {showButton ? <>
        <div id="scrollToTop" className='' onClick={scrollToTop}>
          <img src={Scrolltotop} alt="" width="40" height="40" />
        </div>
      </> : ""}

    </BrowserRouter>
  );
}

export default App;