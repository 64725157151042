import React, { useState } from 'react';

const AlertBox = ({ message, type }) => {

    const [show,setShow]=useState(true);
  let alertClasses;
  let iconClasses;

  function handleClose() {
    setShow(!show)

  }

  switch (type) {
    case 'success':
      alertClasses = 'alert alert-success d-flex justify-content-between align-items-center';
      iconClasses = 'bi bi-check-circle-fill';
      break;
    case 'error':
      alertClasses = 'alert alert-danger d-flex justify-content-between align-items-center';
      iconClasses = 'bi bi-x-circle-fill';
      break;
    case 'warning':
      alertClasses = 'alert alert-warning d-flex justify-content-between align-items-center';
      iconClasses = 'bi bi-exclamation-triangle-fill';
      break;
    case 'info':
      alertClasses = 'alert alert-info d-flex justify-content-between align-items-center';
      iconClasses = 'bi bi-info-circle-fill';
      break;
    default:
      alertClasses = 'alert alert-secondary d-flex justify-content-between align-items-center';
      iconClasses = 'bi bi-info-circle-fill';
  }

  return (

    <>
    
    {show&&<div className='alert-box w-100'>
        <div className={alertClasses} role="alert">
      <span>{message}</span>
      <button onClick={handleClose} type="button" className="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
        
        </div>}
    </>
    
  );
};

export default AlertBox;
