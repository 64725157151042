import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GPSDesign from '../assets/Images/Icons/GreypathDesignLogo.svg'
import { Link } from 'react-router-dom';

const TopHome = ({ scrollToServices, scrollToPartner, scrollToFooter }) => {
    useEffect(() => {

console.log("f4f4f4",scrollToServices);
        return () => {
        };
    }, []);
    return (
        <>
            <div className="bg-grey">
                <div className="container-fl navs bg-grey mb-0">
                    <div className="header-main d-flex justify-content-between">
                        <Link to="/" >
                            <img src={GPSDesign} className="primary-logo pointer" alt="image" />
                        </Link>
                        <div className="text-right">
                            <a href="javascript:void(0);" className="nav-items dis-none" onClick={scrollToServices}>ABOUT</a>
                            <a href="javascript:void(0);" className="nav-items dis-none" onClick={scrollToPartner}>PARTNERS</a>
                            <a href="javascript:void(0);" className="nav-items dis-none" onClick={scrollToFooter}>CONTACT</a>
                        </div>
                        <button id="hiddenButton" style={{ display: "none" }}></button>

                    </div>

                    {/* <div className="col col-md-11 col-lg-8 col-xl-6 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                        <div className="dispaly-flex">
                            <button id="allButton" className="arrow-button all-button" onmousedown="showAll()">All</button>
                            <button className="arrow-button" data-target="sliderss" data-category="WebsiteDesign" onmousedown="toggleButton(this)">Website Design</button>
                            <button className="arrow-button" data-target="sliderss" data-category="WebsiteDevelopment" onmousedown="toggleButton(this)">Website Development</button>
                            <button className="arrow-button" data-target="sliderss" data-category="Branding" onmousedown="toggleButton(this)">Branding</button>
                            <button className="arrow-button" data-target="sliderss" data-category="Presentation" onmousedown="toggleButton(this)">Presentation</button>
                            <button className="arrow-button" data-target="sliderss" data-category="UX/UI" onmousedown="toggleButton(this)"> UX/UI</button>
                            <button className="arrow-button" data-target="sliderss" data-category="PrintingServices" onmousedown="toggleButton(this)">Printing Services</button>
                            <button className="arrow-button" data-target="sliderss" data-category="Content" onmousedown="toggleButton(this)">Content</button>
                            <button className="arrow-button" data-target="sliderss" data-category="ApplicationDesign" onmousedown="toggleButton(this)">Application Design</button>
                            <button className="arrow-button" data-target="sliderss" data-category="Integrations" onmousedown="toggleButton(this)">Integrations</button>

                        </div>
                    </div> */}
                    {/* <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none mt-4 custom-fonts">
                        <p className="para-label mb-0 black_tex lh-18 opt-50">Website Design &#8226; Website Development &#8226; Branding</p>
                        <p className="para-label mb-0 black_tex lh-18 opt-50">Presentations &#8226; UX/UI
                            &#8226; Printing Services</p>
                        <p className="para-label mb-0 black_tex lh-18 opt-50">Content &#8226; Application Design
                            &#8226; Integrations
                        </p>
                    </div> */}
                </div>

            </div>
            {/* <div class="bg-grey">
                <div class='container-fl slicks bg-grey custom-fonts pb-0'>
                    <div class="d-sm-block d-none">
                        <div class="slick-top-sec d-flex">
                            <p class="para-mid text-t-grey mb-2 mr-4">SHOWING ONLY SELECTED PROJECTS</p>
                           
                        </div>

                    </div>
                    <div class="d-block d-sm-none">
                        <div class="slick-top-sec d-flex">
                            <p class="para-mid text-t-grey mb-2 mr-4">SELECTED PROJECTS</p>
                        </div>
                    </div>
                </div>
                <div class="top-bot">
                </div>
            </div> */}

        </>
    )
}
export default TopHome